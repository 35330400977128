module.exports = {
    i18n: {
        //首页
        MyJournal: '我的期刊',
        Directions: '临床指南',
        Viewmore: '查看更多资讯',
        China: '中国',
        USA: '美国',
        USEuro: '欧美',
        Directions: '临床指南', // TODO 临床指南改为深度
        Depth:'深度',
        NewsEvents: '新闻资讯',  // TODO:改为 前沿
        Frontier:'前沿',
        All:'全部',
        HotNews: '热点', 
        Forum:'论坛', 
        Community:'社区',
        Highlight:'精彩回放',
        Progress: '进展',
        MoreNews: '更多资讯',
        //脑计划
        BrainPlan: '脑计划', // TODO：脑计划改为 论坛亮点
        Highlights:'论坛亮点',
        HighLevel:'层次高',
        ContentRefinement:'内容精',
        WideCoverage:'覆盖广',
        HightContents: '本届论坛得到各界单位和领导的重视，将邀请国内外顶尖医学期刊主编、出版社领导、期刊编辑部主任、临床专家等参会。',
        Contents: '邀请国内外知名期刊主编，管理者，业内专家等探讨期刊创刊，运营管理，学术传播等问题。设置「主编视角」、「中外对话」等环节围绕期刊界热点，焦点话题展开讨论。',
        WideContents: '论坛邀请多家媒体对本届论坛开展全方位、多角度的广泛宣传报道。论坛举办期间，将进行网络视频直播。全面地扩大和提升论坛的社会影响力。',
        //临床指南
        Pleaseentercontent: '请输入内容',
        //学术会议
        PastBrainBankAlliancemeetings: '往期脑库联盟会议',
        Periodicals: '期刊',
        Readall: '阅读全部',
        Conferenceprogramme: '会议日程',
        Organization: '组织机构',
        ConferenceKeynote: '会议主旨',
        GuestIntroduction: '嘉宾介绍',
        //期刊 
        regardingPeriodicals: '关于期刊',
        ForumInvitationLetter:'论坛邀请函',
        Viewtheeditorialteam: '查看编辑组',
        //查看详情
        Seedetails: '查看详情',
        //底部 
        LicensingandTechnicalSupport: '许可和技术支持',
        ChiefEditor: '主编辑',// TODO: 主编辑 改为 科学家  
        Scientist:'科学家',
        Editquery: '编辑查询',// TODO:编辑查询 改为 投稿通道  
        SubmissionChannel:'投稿通道',
        Netherlands: '荷兰',  // TODO:荷兰改为网站声明   TODO
        AboutUs:'关于我们',
        WebsiteStatement:'网站声明',
        IntellectualPropertyRights:'知识产权', 
        AllDisclaimersApply:'免责声明', 
        //了解更多
        Learnmore: '了解更多',
        //资讯推荐
        Informationrecommendation: '资讯推荐',
        //附件
        Attachments: '附件',
        //名誉主编
        HonoraryEditorinChief: '名誉总编辑',
        //名誉主编
        ConsultantEditor: '顾问编辑',
        //基础研究方向副主编
        AssociateEditorofBasicResearch: "基础研究方向副主编",
        //临床研究方向副主编
        AssociateEditorofClinicalResearch: '临床研究方向副主编',
        //助理编辑
        AssistantEditor: '助理编辑',
        // TODO:科学家分类修改
        ManagementForumGuests:'管理论坛嘉宾',
        EditorInChiefForumGuests:'主编论坛嘉宾',
        JournalEditorInChiefDomestic:'英文期刊主编(国内)',
        //详情
        Details: '详情',
        //国外主旨演讲科学家
        ForeignKeynoteSpeechScientists: '国外主旨演讲科学家',
        //国内
        NationalKeynoteSpeechScientists: '国内主旨演讲科学家',
        //国际
        Internationalguestspeakers: '国际演讲嘉宾',
        //国际 
        Domesticguests: '国内嘉宾',
        //发布时间 
        Releasetime: '发布时间',
        //阅读全文
        Readthefullarticle: '阅读全文',
        //主编
        EditorChief: '主编',
        //点击此处了解更多期刊信息
        Clickhereformorejournalinformation: '点击此处了解更多期刊信息',
        //介绍
        Introduction: '介绍',

        ApplyStatement:`1、本网站对于所载信息、资料或内容，将尽力提高其准确性与可靠性，但受限于种种客观条件，本网站无法合理保证该些信息、资料或内容之必然或完全准确与可靠。为此，本网站郑重提示本网站用户务必在使用或据此作出判断前作进一步核实。本网站对前述信息、资料或内容及其使用或采信所导致的可能错误、遗漏、歧义和或误解，概不负任何法律责任./n2、本网站所载信息、资料或内容不代表本网站之观点，亦不构成任何投资建议。本网站郑重提示本网站用户勿因对本网站的信任而疏于进一步核实或直接予以使用或采信，本网站对前述该等使用或采信，概不负任何法律责任./n3、对于用户透过本网站及网页、内容而链接及获取的任何信息、资料、内容、产品或服务，本网站概不负任何法律责任，请用户务必自行判断是否点击或使用该（链接），并对其所链接的任何信息、资料、内容、产品或服务的使用或采信，自行审慎判断。/n4、本网站注册用户应自行妥善保管及管理其个人密码等注册信息，如因用户保管或管理不善或者自行告知他人或者以任何方式与他人共享注册账户，而导致任何个人信息、资料泄露或注册账户资源的盗用，本网站概不负任何法律责任。`,
        RightsStatement:`1、本网站对所载全部资料、信息与内容（包括但不限于所使用的标识、图标、图饰、图表、色彩、版面设计、编排方式、程序、技术等）均享有完整的版权及其它相关知识产权或者已经必要且适当的授权，未经本网站事先书面许可，不得擅自复制、链接、转载或以其它任何方式使用。/n2、用户通过登记注册或互动通道向本网站上传信息或内容，即视为该（些）用户已对本网站作出适当授权，本网站无需另经授权即有权在全世界范围内不限形式和载体地享有永久的、不可撤销的、免费的、非独家的使用权，包括但不限于复制、展览、改编、汇编、翻译、信息网络传播和再创作及相关法律法规确定的其它权利。/n3、对于用户通过登记注册或互动通道自行上传的任何信息或内容，不代表本网站的任何立场或观点，本网站对该（些）信息或内容不负任何法律责任，本网站其他用户如发现该（些）内容存在侵犯任何第三人合法权益之情形，请及时告知，本网站将严格按照相关法律法规规定作出删除或予进一步核查等处理。/n4、用户以任何方式使用本网站的任何资料、信息或内容均不应违反前述声明条款，且均应注明来源于本网站及署上作者姓名，如按法律规定需要支付稿酬的，应当于使用前通知本网站及作者并足额支付稿酬，并应确保本网站不因其使用行为而承担任何法律责任。/n5、对于违反前述声明条款或其它侵犯本网站及相关主体知识产权或其它相关权益的行为，本网站保留依法追究法律责任的全部权利。`,        
    }
}