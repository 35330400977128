<template>
  <div class="europeAmerica">
    <head-bgc />
    <div class="contents">
      <!-- 左 -->
      <div class="contents-left">
        <div class="contents-left-bgc">
          <div class="contents-left-bgc-head">
            <div class="contents-left-bgc-head-heng"></div>
            <div class="contents-left-bgc-head-name">{{programsObj.title}}</div>
          </div>
          <div class="contents-left-bgc-img">
            <img src="../../assets/img/theBrainProject/oumei-bgc.png">
          </div>
        </div>
        <div class="contents-left-box">
          <div class="contents-left-box-text">
            <div v-html="programsObj.contentSingle.content"></div>
          </div>
        </div>
      </div>
      <!-- 左 -->

      <!-- 右 -->
      <div class="contents-right">
        <div class="contents-right-head">
          <i class="el-icon-document"></i>
          <div class="contents-right-head-text">
            {{$t('i18n.Informationrecommendation')}}</div>
        </div>
        <ul class="contents-right-ul">
          <li
            class="contents-right-ul-li"
            v-for="(item,index) in list"
            :key="index"
            @click="Details(item)"
          >
            <div class="contents-right-ul-li-img">
              <img :src="imgURL+item.thumb">
            </div>
            <div class="contents-right-ul-li-box">
              <div class="contents-right-ul-li-box-name">
                {{item.description}}
              </div>
              <div class="contents-right-ul-li-box-time">
                {{item.create_time}}
              </div>
            </div>
          </li>
          <div
            class="contents-right-gengduo"
            @click="Readmore"
          >
            <div class="contents-right-gengduo-name">
              {{$t('i18n.Viewmore')}}
            </div>
            <div class="contents-right-gengduo-img">
              <img src="../../assets/img/theBrainProject/gengduo.png">
            </div>
          </div>
        </ul>
      </div>
      <!-- 右 -->
    </div>
  </div>
</template>


<script>
import headBgc from './headBgc.vue'   //导航 
export default {
  name: 'europeAmerica',
  components: {
    headBgc
  },
  data () {
    return {
      imgURL: "",
      list: [],
      programsObj: {
        contentSingle: {}
      }
    }
  },
  created () {
    this.imgURL = localStorage.getItem('imgURL')
    this.getPrograms()
    this.getInformation()
  },
  methods: {
    //获取脑库列表 
    async getPrograms () {
      const { data: data } = await this.$http.get('single', {
        params: {
          cate_id: `/europeAmerica`,
          lang: localStorage.getItem('Language')//切换中英文  
        }
      })
      this.programsObj = data.data
    },
    //资讯推荐
    async getInformation () {
      const { data: data } = await this.$http.get('/news', {
        params: {
          cate_id: '/newsListHotspots',
          lang: localStorage.getItem('Language')//切换中英文 
        }
      })
      this.list = data.data
    },
    //查看详情 
    Details (item) {
      this.$router.push({
        path: 'newsListItem',
        query: {
          id: item.id
        }
      })
    },
    //查看更多
    Readmore () {
      this.$router.push('newsListHotspots')
    }
  }
}

</script>
<style lang="less" scoped>
.europeAmerica {
  .contents {
    position: relative;
    width: 100%;
    padding: 60px 190px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;

    .contents-left {
      display: flex;
      .contents-left-bgc {
        position: absolute;
        width: 553px;
        z-index: 9999;
        .contents-left-bgc-head {
          width: 80px;
          position: relative;
          margin-bottom: 65px;
          .contents-left-bgc-head-heng {
            width: 80px;
            height: 50px;
            background-color: #ff7474;
            position: absolute;
          }
          .contents-left-bgc-head-name {
            position: relative;
            top: 25px;
            left: 6px;
            font-size: 34px;
            color: #393939;
            font-weight: 600;
          }
        }
        .contents-left-bgc-img {
          width: 553px;
          height: 383px;
        }
      }
      .contents-left-box {
        position: relative;
        transform: translateY(4%);
        left: 275px;
        width: 837px;
        height: 576px;
        background-color: pink;
        padding: 46px 44px 40px 340px;
        line-height: 2;
        border-radius: 8px;
        background-color: #f7f8fa;

        .contents-left-box-text {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 15;
          overflow: hidden;
        }
      }
    }

    //右
    .contents-right {
      width: 340px;
      .contents-right-head {
        font-size: 24px;
        color: #fe4c49;
        display: flex;
        align-items: center;
        .contents-right-head-text {
          font-size: 18px;
          color: #393939;
          font-weight: 600;
          margin-left: 3px;
        }
      }
      .contents-right-ul {
        width: 100%;
        background-color: #f7f8fa;
        margin-top: 20px;
        padding: 20px 15px;
        border-radius: 5px;
        .contents-right-ul-li {
          display: flex;
          margin-bottom: 45px;
          cursor: pointer;
          .contents-right-ul-li-img {
            width: 108px;
            height: 61px;
          }
          .contents-right-ul-li-box {
            width: 187px;
            margin-left: 20px;
            line-height: 1.5;
            .contents-right-ul-li-box-time {
              font-size: 13px;
              color: #c9c9c9;
              text-align: right;
              margin-top: 10px;
            }
            .contents-right-ul-li-box-name {
              font-size: 13px;
              color: #393939;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }
        }

        .contents-right-gengduo {
          display: flex;
          align-items: center;
          justify-content: center;
          border-top: 1px solid #e6e6e6;
          padding-top: 10px;
          cursor: pointer;
          .contents-right-gengduo-name {
            font-size: 13px;
            color: #e06c55;
          }
          .contents-right-gengduo-img {
            width: 10px;
            height: 10px;
            margin-left: 7px;
            margin-top: -13px;
          }
        }
      }
    }
  }
}
</style>     