import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/base.css'
Vue.config.productionTip = false

import i18n from './assets/i18n/messages';

import http from './utils/cesguToken'
Vue.prototype.$http = http


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// import './utils/rem'

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')