// 论坛亮点
<template>
  <div class="programs">
    <div class="periodicals-heda">
      <!-- <div class="periodicals-heda-img">
        <img src="../../assets/img/home/frame_work.png">
      </div> -->
      <div class="periodicals-heda-text">{{$t('i18n.Highlights')}}</div>
    </div>
    <div class="contents">
      <div class="contents-tabs">
        <div class="contents-tabs-1">
              <div class="contents-tabs-1-contents">
                <div class="contents-tabs-1-contents-top">
                  <div class="contents-tabs-1-contents-img" v-for="(item,index) in forumHighData" :key="index" @mouseenter="hoverFn(item,index)" @mouseleave="leaveFn(item,index)">
                    <!-- <img :src="imgURL+programsObj.thumb"> -->
                    <img :src="item.url" alt="">
                    <div class="mask" v-show="item.isShow">
                      <!-- <img v-if="imgShow" :src="item.imgUrl" alt="">
                      <img v-else :src="item.imgUrl" alt=""> -->
                      <div class="title">{{ item.title }}</div>
                      <div class="text">{{ item.text }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    </div>

  </div>
</template> 

<script>
export default {
  name: 'programs',
  data () {
    return {
      imgURL: '',
      imgShow:true,
      activeName: 'china',
      programsObj: {
        contentSingle: {}
      },
      forumHighData:[
        {title: this.$t('i18n.HighLevel'), url:require('../../assets/img/home/forumlight_1.png'), imgUrl: require('../../assets/img/home/forumlight1_y.png'),text: this.$t('i18n.HightContents'),isShow:false },
        {title: this.$t('i18n.ContentRefinement'), url:require('../../assets/img/home/forumlight_2.png'), imgUrl: require('../../assets/img/home/forumlight2_y.png'),  text:this.$t('i18n.Contents'), isShow:false },
        {title: this.$t('i18n.WideCoverage'), url:require('../../assets/img/home/forumlight_3.png'), imgUrl: require('../../assets/img/home/forumlight3_y.png'), text:this.$t('i18n.WideContents'), isShow:false }
      ]
    }
  },
  created () {
    this.imgURL = localStorage.getItem('imgURL')//切换中英文 
    this.getPrograms()
    if (localStorage.getItem('Language') == 'zh-cn') {
      this.imgShow = true
    } else {
      this.imgShow = false
    }
  },
  methods: {
    handleClick (tab, event) {
      this.getPrograms()
    },
    //获取脑库列表
    async getPrograms () {
      const { data: data } = await this.$http.get('single', {
        params: {
          cate_id: `/${this.activeName}`,
          lang: localStorage.getItem('Language')//切换中英文  
        }
      })
      this.programsObj = data.data
    },
    //临床指南
    guide1 () {
      this.$router.push('china')
    },
    //临床指南
    guide2 () {
      this.$router.push('unitedStates')
    },
    //临床指南
    guide3 () {
      this.$router.push('europeAmerica')
    },
    hoverFn(item,ind){
      item.isShow=true
    },
    leaveFn(item,ind){
      item.isShow=false
    }
  }
}

</script>
<style lang="less" scoped>
.programs {
  //头部
  // margin:0 auto;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  // width:100%;
  .periodicals-heda {
    margin: 58px 0;
    width: 214px;
    height: 24px;
    background:url('../../assets/img/home/frame_work.png') no-repeat center;
    background-size:100% 100%;
    position: relative;
    .periodicals-heda-text {
      position: relative;
      left:83px;
      top:-8px;
      line-height:26px;
      font-size: 20px;
      color: #393939;
      font-weight: 600;
    }
  }
  //内容
  .contents {
    // max-width:1920px;
    width:100%;
    // padding: 0 190px;
    // max-width:1540px;
    margin:0 auto;
    background: #172954;
    max-height:420px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:44px 0;
    .contents-tabs {

      .contents-tabs-1 {
        //  padding:0 190px;
         max-width:1580px;
         margin:0 auto;
         padding:0 20px;
        // background-color: #ed6f56;
        .contents-tabs-1-contents {
          // margin: 3px;
          padding: 34px 26px 36px 25px;
          border-radius: 22px;
          background-color: #fff;
          box-shadow: 0px 3px 30px 1px #dfdfdf;
          .contents-tabs-1-contents-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // max-width:1540px;
            max-height:338px;
            margin:auto 0;

            .contents-tabs-1-contents-img {
              max-width: 475px;
              // max-height: 268px;
              height:auto;
              border-radius: 20px;
              overflow: hidden;
              // margin-right: 66px;
              cursor:pointer;
              position:relative;
              &:nth-child(1){
                margin-right: 30px;
              }
              &:nth-child(2){
                margin-right: 30px;
              }
              img {
                width: 100%;
                height: 100%;
              }
              .mask{
                width:100%;
                height:100%;
                background:rgba(6,120,164,.8);
                background:#0678A4;
                opacity: .8;
                border-radius: 20px;
                position:absolute;
                left:0;
                top:0;
                // img{
                //   width:100%;
                //   height:100%;
                // }
                color:#ffffff;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding:30px 16px;
                .title{
                  font-size:16px;
                  // margin-top:40px;
                }
                .text{
                  font-size:14px;
                  margin-top:20px;
                  max-width:390px;
                  letter-spacing: .5px;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 8;
                  overflow: hidden;
                  // height: 48px;

                }
                // z-index:1;
              }
            }
            .contents-tabs-1-contents-right {
              flex: 1;
              .contents-tabs-1-contents-right-head {
                font-size: 30px;
                font-weight: 600;
                color: #393939;
                margin-bottom: 38px;
              }
              .contents-tabs-1-contents-right-text {
                width: 100%;
                height: 240px;
                background-color: #f1f1f1;
                border-radius: 21px;
                padding: 42px 25px;
                line-height: 2;
                color: #1e1e1e;
                font-size: 16px;

                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 6;
                overflow: hidden;
                //----------------

                //----------------
              }
            }
          }

          .contents-tabs-1-contents-bottom {
            margin-top: 30px;
            display: flex;
            justify-content: flex-end;
            .contents-tabs-1-contents-bottom-box {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 115px;
              height: 35px;
              background-color: #ed6f56;
              border-radius: 3px;
              cursor: pointer;

              .contents-tabs-1-contents-bottom-text {
                color: #fff;
                font-size: 14px;
                margin-right: 5px;
                cursor: pointer;
              }
              .contents-tabs-1-contents-bottom-img {
                width: 15px;
                height: 11px;
                img {
                  margin-bottom: 5px;
                }
              }
            }
          }
        }
      }
    }
    .html {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
    }
  }
}
</style>   