<template>
  <div class="academicConferences">

    <div class="head">
      <div class="head-1">
        <img
          v-if="imgShow"
          src="../../assets/img/academicConferences/tekan.png"
        >
        <img
          v-else
          src="../../assets/img/academicConferences/tekan_y.png"
        >
      </div>
    </div>

    <div class="contents">
      <!-- <div class="contents-head">
        <div class="contents-head-box"></div>
        <div class="contents-head-text">
          {{$t('i18n.PastBrainBankAlliancemeetings')}}
        </div>
      </div> -->
      <div class="contents-box">
        <ul class="contents-ul">
          <li
            class="contents-ul-li"
            v-for="(item,index) in academicConferencesObj.data"
            :key="index"
            @click="handleOpen(item,index)"
          >
            <div class="file_cover">
              <img :src="imgURL+item.thumb" alt="">
            </div>
            <div class="file_disc">
              <!-- {{ item.description }} -->
              {{ item.title }}
            </div>
          </li>
          <!-- <li
            class="contents-ul-li"
            v-for="(item,index) in academicConferencesObj.data"
            :key="index"
          >
            <div class="contents-ul-li-top">
              <div class="contents-ul-li-top-head">
                {{item.title}}
              </div>
              <div class="contents-ul-li-top-box">
                <div class="contents-ul-li-top-box-time">
                  {{item.contentMeeting.meeting_start_time}}
                </div>
                <div class="contents-ul-li-top-box-di">
                  {{item.contentMeeting.meeting_tag}}
                </div>
              </div>
            </div>
            <div
              class="contents-ul-li-bottom"
              @click="academicConferencesClick(item)"
            >
              <div class="contents-ul-li-bottom-text">
                <div v-html="item.contentMeeting.content"></div>
              </div>
              <div class="contents-ul-li-bottom-chakan">
                <div>{{$t('i18n.Seedetails')}}</div>
                <i class="el-icon-right" />
              </div>
            </div>
          </li> -->
        </ul>
        <div class="contents-box-right">
          <div class="contents-box-right-head">
            <div class="contents-box-right-head-box">
              <img src="../../assets/img/academicConferences/tushu_1.png">
            </div>
            <div class="contents-box-right-head-text">
              {{$t('i18n.Periodicals')}}
            </div>
          </div>
          <div class="contents-box-right-box">
            <div class="contents-box-right-box-img">
              <img :src="imgURL+periodicalsObj.thumb">
            </div>
            <div class="contents-box-right-box-gang"></div>
            <div
              class="contents-box-right-box-text"
              v-if="periodicalsObj.contentSingle"
            >
              <div v-html="periodicalsObj.contentSingle.content"></div>
            </div>
            <div
              class="contents-box-right-box-yuedu"
              @click="readAll(periodicalsObj.id)"
            >【{{$t('i18n.Readall')}}】
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="currentchange"
          @prev-click="prevClick"
          @next-click="nextClick"
          :page-count="academicConferencesObj.last_page"
          :current-page.sync="page"
          hide-on-single-page
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template> 
  
<script>
export default {
  name: 'academicConferences',
  data () {
    return {
      imgURL: '',
      page: 1,//页码
      list_row: 8,//条数 
      academicConferencesObj: {},
      periodicalsObj: {},
      imgShow: true,
    };
  },
  created () {
    this.imgURL = localStorage.getItem('imgURL')
    this.getacademicConferences()
    this.getSingle()
    if (localStorage.getItem('Language') == 'zh-cn') {
      this.imgShow = true
    } else {
      this.imgShow = false
    }
  },
  methods: {
    handleOpen(item,index){
      // console.log(item,index,'查看文件')
      window.open(this.imgURL + item.contentMeeting.upload_file)

    },
    //获取列表
    async getacademicConferences () {
      const { data: data } = await this.$http.get('meeting/list', {
        params: {
          lang: localStorage.getItem('Language'),//切换中英文  
          page: this.page,
          list_row: this.list_row,
          cate_id: '/academicConferences',
        }
      })
      console.log(data.data,777)
      this.academicConferencesObj = data.data
    },
    //详情 
    academicConferencesClick (item) {
      this.$router.push({
        path: 'academicConferencesDeatils',
        query: {
          id: item.id
        }
      })
    },
    //获取期刊
    async getSingle () {
      const { data: data } = await this.$http.get('single', {
        params: {
          lang: localStorage.getItem('Language'),
          cate_id: '/periodicals'
        }
      })
      this.periodicalsObj = data.data
    },
    //阅读全部
    readAll (item) {
      this.$router.push({
        path: 'periodicals',
        query: {
          id: item.id
        }
      })
    },
    //点击每一页 
    currentchange (e) {
      this.page = e
      setTimeout(() => {
        this.getacademicConferences()
      }, 500)
      // this.$Spin.show(); 
    },
    //上一页  
    prevClick (e) {
      this.page = e
      setTimeout(() => {
        this.getacademicConferences()
      }, 500)
      // this.$Spin.show();
    },
    //下一页 
    nextClick (e) {
      this.page = e
      setTimeout(() => {
        this.getacademicConferences()
      }, 500)
      // this.$Spin.show();
    },
  }
}

</script>
<style lang="less" scoped>
.academicConferences {
  .head {
    position: relative;
    .head-1 {
      width: 100%;
      height: 174px;
      position: relative;
    }
    .head-2 {
      width: 719px;
      height: 44px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  //内容
  .contents {
    padding: 0 190px;
    // .contents-head {
    //   position: relative;
    //   margin-top: 28px;
    //   margin-bottom: 50px;
    //   .contents-head-box {
    //     position: absolute;
    //     width: 80px;
    //     height: 50px;
    //     background-color: #0647C7;
    //   }
    //   .contents-head-text {
    //     position: relative;
    //     top: 32px;
    //     left: 30px;
    //     color: #363636;
    //     font-size: 22px;
    //     font-weight: 600;
    //   }
    // }
    margin-top: 78px;
    .contents-box {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;      
      .contents-ul {
        margin-right: 37px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 1070px;
        .contents-ul-li {
          display: flex;
          // justify-content: center;
          align-items: center;
          width:512px;
          height:208px;
          background:#EFF4FC;
          border-radius:11px;
          padding:16px 14px;
          cursor:pointer;
          margin-bottom:42px;
          .file_cover{
            width:123px;
            height:175px;
            // height:auto;
            margin-right:16px;
            // padding:10px 0px 10px 14px;
            display: flex;
            flex-shrink: 0;
            img {
              width:100%;
              height:100%;
            }
          }
          .file_disc{
            font-size:18px;
            font-weight:bold;
            color:#2D2D2D;
          }
          // position: relative;
          // .contents-ul-li-top {
          //   position: relative;
          //   width: 463px;
          //   background-color: #f7f8fa;
          //   padding: 28px 30px 60px 28px;
          //   border-radius: 11px;
          //   .contents-ul-li-top-head {
          //     font-size: 18px;
          //     color: #2d2d2d;
          //     font-weight: 600;
          //     display: -webkit-box;
          //     -webkit-box-orient: vertical;
          //     -webkit-line-clamp: 2;
          //     overflow: hidden;
          //   }
          //   .contents-ul-li-top-box {
          //     display: flex;
          //     justify-content: space-between;
          //     align-items: center;
          //     margin-top: 12px;
          //     .contents-ul-li-top-box-time {
          //       color: #bcbcbc;
          //       font-size: 13px;
          //     }
          //     .contents-ul-li-top-box-di {
          //       // width: 67px;
          //       min-width: 67px;
          //       padding: 0 5px;
          //       height: 35px;
          //       background-color: #7070b7;
          //       color: #fff;
          //       font-size: 13px;
          //       border-radius: 3px;
          //       display: flex;
          //       align-items: center;
          //       justify-content: center;
          //       // cursor: pointer;
          //     }
          //   }
          // }
          // .contents-ul-li-bottom {
          //   position: relative;
          //   top: -40px;
          //   left: 43px;
          //   width: 376px;
          //   padding: 22px 16px;
          //   border: 1px solid #e9e9e9;
          //   border-radius: 11px;
          //   font-size: 14px;
          //   color: #2d2d2d;
          //   background-color: #ffffff;
          //   z-index: 999;
          //   cursor: pointer;
          //   .contents-ul-li-bottom-text {
          //     line-height: 1.7;
          //     display: -webkit-box;
          //     -webkit-box-orient: vertical;
          //     -webkit-line-clamp: 6;
          //     overflow: hidden;
          //   }
          //   .contents-ul-li-bottom-chakan {
          //     display: flex;
          //     align-items: center;
          //     margin-top: 30px;
          //     font-size: 14px;
          //     color: #ed6f56;
          //   }
          // }
        }
      }
      //-----
      .contents-box-right {
        position: relative;
        padding-left: 50px;
        border-left: 1px solid #e8e8e8;
        .contents-box-right-head {
          position: relative;
          top: 0;
          left: -40px;
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          .contents-box-right-head-box {
            width: 28px;
            height: 24px;
            margin-right: 7px;
          }
          .contents-box-right-head-text {
            font-size: 18px;
            color: #363636;
            font-weight: 600;
          }
        }
        .contents-box-right-box {
          background-color: #f7f8fa;
          padding: 35px 30px;
          height:903px;
          .contents-box-right-box-img {
            width: 306px;
            height: 354px;
          }
          .contents-box-right-box-gang {
            width: 25px;
            height: 5px;
            background-color: #0647c7;
            margin-top: 50px;
            margin-bottom: 20px;
          }
          .contents-box-right-box-text {
            width: 320px;
            color: #2d2d2d;
            font-size: 15px;
            line-height: 1.5;
            margin-bottom: 22px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 11;
            overflow: hidden;
          }
          .contents-box-right-box-yuedu {
            text-align: right;
            font-size: 16px;
            color: #0647c7;
            cursor: pointer;
          }
        }
      }
      //-----
    }

    .page {
      padding: 20px 0 55px 0;
      text-align: center;
      // /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      //   background-color: rgb(255, 116, 116);
      // }
      // /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
      //   color: rgb(255, 116, 116);
      // }
    }
  }
}
</style>      