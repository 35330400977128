// 新闻资讯 
<template>
  <div class="news-events">
    <div class="periodicals-heda">
      <!-- <div class="periodicals-heda-img">
        <img src="../../assets/img/home/frame_work.png">
      </div> -->
      <!-- TODO:新闻资讯版块 标题改为 前沿 -->
      <div class="periodicals-heda-text">{{$t('i18n.Frontier')}}</div>
    </div>
    <div class="news-events-contents">
      <ul class="news-events-contents-ul">
        <li
          class="news-events-contents-item"
          v-for="(item,index) in list"
          :key="index"
          @click="newsEventsItem(item)"
        >
          <div class="news-events-contents-item-img">
            <img :src="imgURL+item.thumb">
          </div>
          <div class="news-events-contents-item-head ">
            <div class="hidden">
              {{item.title}}
            </div>
          </div>
          <div class="news-events-contents-item-text ">
            <div class="hiddens">
              {{item.description}}
            </div>
          </div>
          <div class="news-events-contents-item-time">{{item.create_time}}</div>
        </li>
      </ul>
    </div>
    <div
      class="primary-btn"
      @click="primaryBtn"
    >
      <div class="primary-flex">
        <div class="primary-flex-img">
          <img src="../../assets/img/home/periodicals-btn.png">
        </div>
        <div class="primary-flex-text">{{$t('i18n.MoreNews')}}</div>
      </div>
    </div>
  </div>
</template> 

<script>
export default {
  name: 'newsEvents',
  data () {
    return {
      imgURL: '',
      list: []
    }
  },
  created () {

    this.imgURL = localStorage.getItem('imgURL')//切换中英文   
    this.getNewsEvents()
  },
  methods: {
    //获取列表
    async getNewsEvents () {
      const { data: data } = await this.$http.get('/news', {
        params: {
          cate_id: '/newsListHotspots',
          lang: localStorage.getItem('Language')//切换中英文 
        }
      })
      this.list = data.data
    },
    //详情 
    newsEventsItem (item) {
      this.$router.push({
        path: 'newsListItem',
        query: {
          id: item.id
        }
      })
    },
    //更多资讯  
    primaryBtn () {
      this.$router.push('news')
    },

  }
}

</script>
<style lang="less" scoped>
.news-events {
  //头部
  // margin:0 auto;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  .periodicals-heda {
    margin: 58px 0;
    width: 214px;
    height: 24px;
    background:url('../../assets/img/home/frame_work.png') no-repeat center;
    background-size:100% 100%;
    position: relative;
    .periodicals-heda-text {
      position: relative;
      left:104px;
      top:-8px;
      line-height:26px;
      font-size: 20px;
      color: #393939;
      font-weight: 600;
    }
  }
  //内容
  .news-events-contents {
    // padding: 0 190px;
    background-color: #fff;
    max-width:1580px;
    padding:0 20px;
    margin:0 auto;
    display: flex;
    justify-content: center;
    // box-sizing: content-box;
    .news-events-contents-ul {
      display: flex;
      padding-bottom: 45px;
      .hidden {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        height: 48px;
        margin-top:22px;
      }
      .hiddens {
        min-height: 40px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .news-events-contents-item {
        background-color: #fff;
        padding: 24px 22px 18px 22px;
        border-radius: 11px;
        box-shadow: 0px 3px 30px 1px #dfdfdf;
        cursor: pointer;
        margin-right: 44px;
        transition: 0.4s all;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          background-color: #0647c7;
          box-shadow: 0px 3px 30px 1px #dfdfdf;
          .news-events-contents-item-head {
            color: #fff;
          }
          .news-events-contents-item-text {
            color: #fff;
            border-bottom: 1px solid #fff;
          }
          .news-events-contents-item-time {
            color: #fff;
          }
        }
        .news-events-contents-item-img {
          max-width: 307px;
          // max-height: 173px;
          height:auto;
          
          img {
            width: 100%;
            height: 100%;
          }
        }
        .news-events-contents-item-head {
          max-width: 300px;
          font-size: 18px;
          color: #393939;
          font-weight: 600;
          margin: 25px 0 20px 0;
        }
        .news-events-contents-item-text {
          max-width: 300px;
          font-size: 15px;
          color: #999999;
          padding-bottom: 23px;
          border-bottom: 1px solid #d8d8d8;
        }
        .news-events-contents-item-time {
          padding-top: 18px;
          font-size: 16px;
          color: #a7a7a7;
          text-align: right;
        }
      }
    }
  }
  //按钮
  .primary-btn {
    margin: 5px 0 60px 0;
    display: flex;
    justify-content: center;
    cursor: pointer;
    .primary-flex {
      width: 134px;
      padding: 10px 0;
      border-radius: 20px;
      background-color: #0647c7;
      display: flex;
      align-items: center;
      justify-content: center;

      .primary-flex-img {
        width: 14px;
        height: 17px;
        margin-right: 5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .primary-flex-text {
        font-size: 16px;
        color: #ffffff;
      }
    }
  }
}
</style>  