<template>
  <div class="clinicalGuidelinesItme">
    <div class="head">
      <img
        v-if="imgShow"
        src="../../assets/img/clinicalGuidelines/head.png"
      >
      <img
        v-else
        src="../../assets/img/clinicalGuidelines/head-y.png"
      >
    </div>
    <div class="contents">
      <div class="contents-head">{{calDetail.title}}</div>
      <div style="display:flex;">
        <div class="contents-time">
          {{$t('i18n.EditorChief')}}：{{calDetail.author}}
        </div>
        <div class="contents-time">
          {{$t('i18n.Releasetime')}}：{{calDetail.create_time_text}}
        </div>
        <div class="contents-name">{{calDetail.subtitle}}</div>
      </div>
      <div
        class="contents-box"
        v-if="calDetail.contentClinical"
      >
        <div v-html="calDetail.contentClinical.content"></div>
      </div>
    </div>
  </div>
</template>  
 
<script> 
export default {
  name: 'clinicalGuidelinesItme',
  data () {
    return {
      calDetail: {},
      imgShow: true,
    }
  },
  created () {
    if (localStorage.getItem('Language') == 'zh-cn') {
      this.imgShow = true
    } else {
      this.imgShow = false
    }
    this.getMedicalDetail()
  },
  methods: {
    async getMedicalDetail () {
      const { data: data } = await this.$http.get('medical_detail', {
        params: {
          id: this.$route.query.id,
          lang: localStorage.getItem('Language')//切换中英文  
        }
      })
      console.log(data);
      this.calDetail = data.data
    }
  }
}
</script> 
<style lang="less" scoped>
.clinicalGuidelinesItme {
  .head {
    width: 100%;
    height: 116px;
  }

  .contents {
    min-height: 500px;
    padding: 0 190px;
    .contents-head {
      margin: 15px 0 25px 0;
      font-size: 22px;
      color: #000;
    }

    .contents-time {
      color: #bcbcbc;
      font-size: 14px;
      margin-bottom: 40px;
      margin-right: 35px;
    }
    .contents-name {
      color: #0647c7;
      font-size: 14px;
      margin-bottom: 40px;
    }

    .contents-box {
      padding: 25px 52px;
      background-color: #f5f5f5;
      margin-bottom: 50px;
      line-height: 2;
    }
  }
}
</style>       