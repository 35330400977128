<template>
  <div class="home">
    <nav-menu />
    <router-view></router-view>
    <bottom />
  </div>
</template>   

<script>
import navMenu from './navMenu.vue'   //导航 
import bottom from './bottom.vue'//底部  
export default {
  name: 'Home',
  data () {
    return {
    };
  },
  components: {
    navMenu,
    bottom
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.home {
  // width: 100%;
  max-width: 1920px;
  // min-width: 1880px;
  background-color: #fff;
}
</style> 
   