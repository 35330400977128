<template>
  <div class="box">
    <div class="head-img">
      <img src="../../assets/img/theBrainProject/bgc.png">
    </div>
  </div>
</template>

<script>
export default {
  name: 'headBgc'

}

</script>
<style lang="less" scoped>
.box {
  width: 100%;
  .head-img {
    width: 100%;
    height: 85px;
  }
}
</style>