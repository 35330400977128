//期刊
<template>
  <div class="periodicals">
    <div class="periodicals-heda">
      <!-- <div class="periodicals-heda-img">
        <img src="../../assets/img/home/frame_work.png">
      </div> -->
      <div class="periodicals-heda-text">{{$t('i18n.MyJournal')}}</div>
    </div>
    <!-- 内容 -->
    <div class="periodicals-contents">
      <div class="periodicals-contents-left">
        <img :src="imgURL + periodicalsObj.thumb">
      </div>
      <div class="periodicals-contents-right">
        <div class="periodicals-contents-right-head">
          <span
            class="periodicals-contents-right-head-q">{{periodicalsObj.title}}</span>
          <span class="periodicals-contents-right-head-j">
            {{$t('i18n.Introduction')}}
          </span>
        </div>
        <div
          v-if="periodicalsObj.contentSingle"
          class="html"
          v-html="periodicalsObj.contentSingle.content"
        ></div>
        <div
          class="periodicals-contents-right-box"
          @click="$router.push('periodicals')"
        >
          <div class="periodicals-contents-right-box-img">
            <img src="../../assets/img/home/jiantou.png">
          </div>
          <div class="periodicals-contents-right-box-name">
            {{$t('i18n.Clickhereformorejournalinformation')}}
          </div>
        </div>
      </div>
    </div>
    <!-- 内容 -->

  </div>
</template>

<script> 
export default {
  name: 'periodicals',
  data () {
    return {
      periodicalsObj: { contentSingle: { content: [] } },
      imgURL: '',
      content: ''
    };
  },
  created () {
    this.getSingle()
    this.imgURL = localStorage.getItem('imgURL')//切换中英文   
  },
  methods: {
    async getSingle () {
      const { data: data } = await this.$http.get('single', {
        params: {
          lang: localStorage.getItem('Language'),
          cate_id: '/periodicals'
        }
      })
      this.periodicalsObj = data.data
    }
  }
}

</script>
<style lang="less" scoped>
.periodicals {
  //头部
  // margin:0 auto;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  .periodicals-heda {
    margin: 58px 0;
    width: 214px;
    height: 24px;
    background:url('../../assets/img/home/frame_work.png') no-repeat center;
    background-size:100% 100%;
    position: relative;
    .periodicals-heda-text {
      position: relative;
      left:83px;
      top:-8px;
      line-height:26px;
      font-size: 20px;
      color: #393939;
      font-weight: 600;
    }
  }

  //内容
  .periodicals-contents {
    padding: 55px 260px 105px 245px;
    display: flex;
    background-color: #f6f6f6;
    .periodicals-contents-left {
      width: 287px;
      height: 342px;
      margin-right: 70px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .periodicals-contents-right {
      flex: 1;
      line-height: 2;
      .periodicals-contents-right-head {
        margin-bottom: 43px;
        .periodicals-contents-right-head-q {
          font-size: 24px;
          color: #393939;
          font-weight: 600;
          margin-right: 3px;
        }
        .periodicals-contents-right-head-j {
          font-size: 16px;
          color: #0647C7;
          font-weight: 600;
          margin-right: 3px;
        }
      }
      .periodicals-contents-right-text {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
      }
    }
    .rectangular {
      width: 14px;
      height: 5px;
      background-color: #0647C7;
      margin-right: 5px;
    }
    .general-text {
      font-size: 16px;
      color: #0647C7;
      font-weight: 600;
    }

    .highlights-contents {
      font-size: 16px;
      color: #393939;
      margin-bottom: 40px;
    }
    .fields-contents {
      font-size: 16px;
      color: #393939;
    }

    .html {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 11;
      overflow: hidden;
      font-size:16px;
    }
    .periodicals-contents-right-box {
      display: flex;
      margin-top: 40px;
      justify-content: flex-end;
      cursor: pointer;
      .periodicals-contents-right-box-img {
        width: 32px;
        height: 32px;
      }
      .periodicals-contents-right-box-name {
        font-size: 16px;
        color: #0647C7;
        border-bottom: 1px solid #0647C7;
      }
    }
  }
}
</style>    