<template>
  <div class="periodicals">
    <div class="contents">
      <div class="contents-left">

        <div
          class="html"
          v-if="periodicalsObj.contentSingle"
        >
          <div v-html="periodicalsObj.contentSingle.content"></div>
        </div>

        <!-- <div class="contents-left-heng">
          <div class="heng"></div>
          <div class="name">重点和范围</div>
        </div>

        <div class="contents-left-zhong">
          Human Brain
          (2957-6296)支持对神经科学和临床神经病学的整个流派进行同行评审的前沿研究。该杂志最好使用各种方法（
          例如，组织学、神经病理学、生化、分子、信息学、脑成像）发表来自死后大脑、手术切除的大脑样本、活体受
          试者和体外模型的原始数据。涉及脑尸检作为最终测量的临床研究非常受欢迎。文章格式包括原始研究、病例报
          告和系统评价，以及技术创新，例如用于分子定位的新型脑组织处理、脑成像分析、蛋白质组学、基因组学和表
          观基因组学研究。
          <br><br>
          本刊的核心重点是人脑研究，重点关注对人脑和脑疾病的认识进展，以及与人脑相关的协作研究平台和数据库的开
          发。促进对人类大脑和大脑疾病的理解，以及与人类大脑相关的协作研究平台和数据库的开发。
        </div>

        <div class="contents-left-heng">
          <div class="heng"></div>
          <div class="name">本刊的主要研究领域包括但不限于：</div>
        </div>
        <div class="contents-left-xianzhi">
          研究控制人类大脑功能和活动的机制，包括发育、衰老和疾病。<br>
          研究人脑疾病的发病机制，包括神经退行性疾病、脑血管疾病、脑外伤、脑肿瘤、神经心理疾病和其他神经系统疾病。<br>
          临床研究，重点是改善神经系统疾病的临床实践。<br>
          人脑疾病、衰老或发育的生物标志物鉴定。<br>
          死前和死后人脑的神经影像学研究。<br>
          人脑结构和功能的临床研究，特别是以脑解剖检查为最终测量。<br>
          用于人脑组织库和研究的新处理方法。<br>
          人脑组织库的标准操作规程和管理。<br>
          人脑的区域和国际比较研究。<br>
          介绍用于研究人脑的数据库和平台。<br>
          人类大脑功能和疾病的队列研究。<br>
        </div>

        <div class="contents-left-heng bian">
          <div class="contents-left-box">
            <div class="heng"></div>
            <div class="contents-left-box-name">频率：</div>
          </div>
          <div class="contents-left-box-text">每季</div>
        </div>

        <div class="contents-left-heng bian">
          <div class="contents-left-box">
            <div class="heng"></div>
            <div class="contents-left-box-name">开放获取政策：</div>
          </div>
          <div class="contents-left-box-text">
            该期刊提供对其内容的即时开放获取，其原则是向公众免费提供研究以支持更大的全球知识交流。
          </div>
        </div>

        <div class="contents-left-heng bian">
          <div class="contents-left-box">
            <div class="heng"></div>
            <div class="contents-left-box-name">开放获取政策：</div>
          </div>
          <div class="contents-left-box-text">
            该期刊提供对其内容的即时开放获取，其原则是向公众免费提供研究以支持更大的全球知识交流。
          </div>
        </div>

        <div class="contents-left-heng">
          <div class="heng"></div>
          <div class="name">版权和许可</div>
        </div>
        <div class="contents-left-zhong">
          作者保留根据 Attribution-NoDerivatives 4.0 International (CC BY-ND 4.0)
          许可发布的材料的版权。
        </div>

        <div class="contents-left-heng">
          <div class="heng"></div>
          <div class="name">存档</div>
        </div>
        <div class="contents-left-zhong">
          Human Brain 发布的内容由 CLOCKSS、LOCKSS 和 Portico 收集、保存和服务。
        </div>

        <div class="contents-left-heng">
          <div class="heng"></div>
          <div class="name">出版商</div>
        </div>
        <div class="contents-left-zhong">
          欧亚学术出版集团（ISNI：0000 0005 0723 3170），中国香港。
        </div> -->

        <!-- 左侧盒子 -->
      </div>
      <!-- 右 -->
      <about />
      <!-- 右 -->
    </div>
  </div>
</template>
 
<script> 
import about from './about.vue'//右侧  
export default {
  name: 'periodicals',
  components: {
    about
  },
  data () {
    return {
      periodicalsObj: {},
      imgURL: ''
    }
  },
  created () {
    this.imgURL = localStorage.getItem('imgURL')
    this.getSingle()
  },
  methods: {
    //获取期刊
    async getSingle () {
      const { data: data } = await this.$http.get('single', {
        params: {
          lang: localStorage.getItem('Language'),
          cate_id: '/periodicals'
        }
      })
      this.periodicalsObj = data.data
    },
  }
}

</script> 
<style lang="less" scoped>
.contents {
  padding: 45px 190px;
  width: 100%;
  background-color: #fff;
  max-width:1540px;
  margin:0 auto;
  display: flex;
  justify-content: space-between;

  .contents-left {
    max-width: 1090px;
    background-color: #ffffff;
    padding: 38px 52px 10px 40px;
    line-height: 2;
    font-size: 14px;
    color: #2d2d2d;
    border-right: 1px solid #e8e8e8;
    .contents-left-heng {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .heng {
        width: 5px;
        height: 18px;
        background-color: #0647c7;
        margin-right: 8px;
      }
      .name {
        font-size: 18px;
        color: #363636;
        font-weight: 600;
      }
      .contents-left-box {
        display: flex;
        align-items: center;
        font-size: 18px;
        min-width: 67px;
        .contents-left-box-name {
          font-size: 18px;
          color: #363636;
          font-weight: 600;
        }
      }
      .contents-left-box-text {
        font-size: 16px;
        color: #363636;
      }
    }
    .bian {
      margin-bottom: 40px;
    }
    .contents-left-zhong {
      margin-bottom: 60px;
    }

    .contents-left-xianzhi {
      margin-bottom: 60px;
    }

    .html {
      font-size: 16px;
      line-height: 3;
    }
  }
}
</style>    