<template>
  <div class="expertDetails">
    <div
      class="expertDetails-box"
      v-if="expertDetailsObj.contentEditor"
    >
      <div v-html="expertDetailsObj.contentEditor.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'expertDetails',
  data () {
    return {
      expertDetailsObj: {}
    };
  },
  created () {
    this.getDetails()
  },
  methods: {
    async getDetails () {
      const { data: data } = await this.$http.get('editor_detail', {
        params: {
          id: this.$route.query.id,
          lang: localStorage.getItem('Language')//切换中英文  
        }
      })
      this.expertDetailsObj = data.data
    }
  }

}

</script>
<style lang="less" scoped>
.expertDetails {
  min-height: 500px;
  .expertDetails-box {
    padding: 45px 190px;
    line-height: 2;
  }
}
</style>  