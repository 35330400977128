<template>
  <div class="china">
    <head-bgc />
    <div class="contents">
      <!-- 左 -->
      <div class="contents-left">
        <div class="contents-left-top">
          <div class="contents-left-top-img">
            <img src="../../assets/img/theBrainProject/zhongguo-bgc.png">
          </div>
          <div class="contents-left-top-name">{{programsObj.title}}</div>
          <div class="contents-left-top-box">
            <div class="contents-left-top-box-img">
              <img src="../../assets/img/theBrainProject/zhongguo-bgc2.png">
            </div>
            <div class="contents-left-top-box-text">
              <div class="contents-left-top-box-text-wen">
                <!-- 2021年9月由中华人民共和国科学技术部发布科技创新2030—“脑科学与类脑研
                究”重大项目申报指南。按照指南要求，脑科学与类脑研究重大项目2021年度围
                绕脑认知原理解析、认知障碍相关重大脑疾病发病机理与干预技术、类脑计算与
                脑机智能技术及应用、儿童青少年脑智发育、技术平台建设5个方面开展研究，共
                部署指南方向59个，国拨经费概算31.48亿元。 -->
                <div v-html="programsObj.contentSingle.content"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="contents-left-btoom">
          <div class="contents-left-btoom-fj">
            <div class="contents-left-btoom-fj-img">
              <img src="../../assets/img/theBrainProject/fujian.png">
            </div>
            <div class="contents-left-btoom-fj-name">{{$t('i18n.Attachments')}}
            </div>
          </div>
          <div
            class="contents-left-btoom-box"
            @click="Download(programsObj.contentSingle.attachment_url)"
          >
            <div class="contents-left-btoom-box-name">
              {{programsObj.contentSingle.attachment_name}}
            </div>
            <div class="contents-left-btoom-box-img">
              <img src="../../assets/img/theBrainProject/dianji.png">
            </div>
          </div>
        </div>
      </div>
      <!-- 左 -->
      <!-- 右 -->
      <div class="contents-right">
        <div class="contents-right-head">
          <i class="el-icon-document"></i>
          <div class="contents-right-head-text">
            {{$t('i18n.Informationrecommendation')}}
          </div>
        </div>

        <ul class="contents-right-ul">
          <li
            class="contents-right-ul-li"
            v-for="(item,index) in list"
            :key="index"
            @click="Details(item)"
          >
            <div class="contents-right-ul-li-img">
              <img :src="imgURL+item.thumb">
            </div>
            <div class="contents-right-ul-li-box">
              <div class="contents-right-ul-li-box-name">
                {{item.description}}
              </div>
              <div class="contents-right-ul-li-box-time">
                {{item.create_time}}
              </div>
            </div>
          </li>
          <div
            class="contents-right-gengduo"
            @click="Readmore"
          >
            <div class="contents-right-gengduo-name">
              {{$t('i18n.Viewmore')}}
            </div>
            <div class="contents-right-gengduo-img">
              <img src="../../assets/img/theBrainProject/gengduo.png">
            </div>
          </div>
        </ul>
      </div>
      <!-- 右 -->

    </div>
  </div>
</template>  

<script> 
import headBgc from './headBgc.vue'   //导航 
export default {
  name: 'china',
  components: {
    headBgc
  },
  data () {
    return {
      imgURL: "",
      list: [],
      programsObj: {
        contentSingle: {}
      }
    }
  },
  created () {
    this.imgURL = localStorage.getItem('imgURL')
    this.getPrograms()
    this.getInformation()
  },
  methods: {
    //获取脑库列表
    async getPrograms () {
      const { data: data } = await this.$http.get('single', {
        params: {
          cate_id: `/china`,
          lang: localStorage.getItem('Language')//切换中英文  
        }
      })
      this.programsObj = data.data
    },
    //下载附件 
    Download (item) {
      window.open(item)
    },
    //资讯推荐
    async getInformation () {
      const { data: data } = await this.$http.get('/news', {
        params: {
          cate_id: '/newsListHotspots',
          lang: localStorage.getItem('Language')//切换中英文 
        }
      })
      this.list = data.data
    },
    //查看详情 
    Details (item) {
      this.$router.push({
        path: 'newsListItem',
        query: {
          id: item.id
        }
      })
    },
    //查看更多
    Readmore () {
      this.$router.push('newsListHotspots')
    }
  }
}

</script>
<style lang="less" scoped>
.china {
  width: 100%;
  .contents {
    position: relative;
    width: 100%;
    padding: 60px 190px;
    background-color: #fff;

    display: flex;
    justify-content: space-between;

    //左
    .contents-left {
      // width: 1150px;
      position: relative;
      //shang
      .contents-left-top {
        display: flex;
        .contents-left-top-img {
          width: 486px;
          height: 315px;
          position: relative;
          z-index: 999;
          background-color: #8898b5;
          border-radius: 20px 0px 20px 20px;
        }
        .contents-left-top-name {
          width: 190px;
          height: 90px;
          color: #fff;
          font-size: 34px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px 0px 0px 8px;
          background: #e06c55;
          z-index: 99999;
          position: absolute;
          top: 30px;
          left: 440px;
        }
        .contents-left-top-box {
          position: relative;
          top: 0;
          left: -50px;
          width: 711px;
          height: 405px;
          .contents-left-top-box-img {
            width: 711px;
            height: 405px;
          }
          .contents-left-top-box-text {
            padding: 0 60px 70px 80px;
            position: absolute;
            bottom: 0;
            left: 0;
            line-height: 2;
            color: #fff;
            width: 710px;
            height: 230px;
            .contents-left-top-box-text-wen {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5;
              overflow: hidden;
            }
          }
        }
      }
      //xia
      .contents-left-btoom {
        width: 1150px;
        height: 90px;
        margin-top: 70px;
        background-color: #f3f3f3;
        border-radius: 5px;
        display: flex;
        align-items: center;
        .contents-left-btoom-fj {
          display: flex;
          align-items: center;
          margin-left: 60px;
          margin-right: 20px;
          .contents-left-btoom-fj-img {
            width: 18px;
            height: 28px;
            margin-right: 10px;
          }
          .contents-left-btoom-fj-name {
            font-size: 18px;
            color: #393939;
            font-weight: 600;
          }
        }
        .contents-left-btoom-box {
          display: flex;
          align-items: center;
          box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
          border-radius: 24px;
          padding: 13px 26px;
          cursor: pointer;
          .contents-left-btoom-box-img {
            width: 27px;
            height: 25px;
            margin-left: 10px;
          }
          .contents-left-btoom-box-name {
            color: #203d7f;
          }
        }
      }
    }

    //右
    .contents-right {
      width: 340px;
      .contents-right-head {
        font-size: 24px;
        color: #fe4c49;
        display: flex;
        align-items: center;
        .contents-right-head-text {
          font-size: 18px;
          color: #393939;
          font-weight: 600;
          margin-left: 3px;
        }
      }
      .contents-right-ul {
        width: 100%;
        background-color: #f7f8fa;
        margin-top: 20px;
        padding: 20px 15px;
        border-radius: 5px;
        .contents-right-ul-li {
          display: flex;
          margin-bottom: 45px;
          cursor: pointer;
          .contents-right-ul-li-img {
            width: 108px;
            height: 61px;
          }
          .contents-right-ul-li-box {
            width: 187px;
            margin-left: 20px;
            line-height: 1.5;
            .contents-right-ul-li-box-time {
              font-size: 13px;
              color: #c9c9c9;
              text-align: right;
              margin-top: 10px;
            }
            .contents-right-ul-li-box-name {
              font-size: 13px;
              color: #393939;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }
        }

        .contents-right-gengduo {
          display: flex;
          align-items: center;
          justify-content: center;
          border-top: 1px solid #e6e6e6;
          padding-top: 10px;
          cursor: pointer;
          .contents-right-gengduo-name {
            font-size: 13px;
            color: #e06c55;
          }
          .contents-right-gengduo-img {
            width: 10px;
            height: 10px;
            margin-left: 7px;
            margin-top: -13px;
          }
        }
      }
    }
  }
}
</style>   