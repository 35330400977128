import axios from 'axios'
import { Message, Loading } from 'element-ui'
// const ConfigBaseURL = 'http://192.168.100.101/api.php/' //默认路径，这里也可以使用env来判断环境    
const ConfigBaseURL = 'http://wmjforum.com/api.php/' //默认路径，这里也可以使用env来判断环境     
// const ConfigBaseURL = 'http://api.humanbrain.org.cn/api.php/' //默认路径，这里也可以使用env来判断环境


//使用create方法创建axios实例
export const service = axios.create({
    baseURL: ConfigBaseURL,
    timeout: 10000
})

/* 请求拦截器（请求之前的操作） */
service.interceptors.request.use(config => {
    //  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    // config.headers.hkcms_lang = `${localStorage.getItem('Language')}`
    // config.headers.hkcms_lang = `en` 
    return config
})

/* 相应拦截 */
service.interceptors.response.use(
    res => {
        //这里用于处理返回的结果，比如如果是返回401无权限，可能会是跳回到登录页的操作，结合自己的业务逻辑写 
        if (res.data.code === 401) {
            Toast("无权限操作")
        }
        if (res.data.code === 400) {
            Toast("请求网络失败")
        }
        if (res.data.code === 404) {
            Toast("请求网络失败")
        }
        return res;
    },
    err => {
        if (err) {}
        return Promise.reject(err);
    }
)

export default service