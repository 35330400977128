<template>
  <div class="home">
    <carousel />
    <periodicals />
    <directions />
    <programs />
    <newsEvents />
  </div>
</template>

<script> 
import carousel from '../HomeContents/carousel.vue'//轮播图 
import periodicals from '../HomeContents/periodicals.vue'//期刊 
import directions from '../HomeContents/directions.vue'//指南
import programs from '../HomeContents/programs.vue'//脑计划
import newsEvents from '../HomeContents/newsEvents.vue'//新闻资讯  
export default {
  name: 'homeContents',
  data () {
    return {
    };
  },
  components: {
    carousel,
    periodicals,
    directions,
    newsEvents,
    programs,
  },
  created () {
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  background-color: #fff;
}
</style>