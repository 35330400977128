<template>
  <div class="aboutMembers">
    <div class="contents">
      <!-- 左 -->
      <div class="contents-left">

        <!-- 管理论坛嘉宾 -->
        <div class="general">
          <div class="general-heng"></div>
          <div class="general-name">{{$t('i18n.ManagementForumGuests')}}:</div>
        </div>
        <ul class="fame-ul">
          <li
            v-for="(item,index) in list1"
            :key="index"
          >
            <div class="fame-ul-li">
              <div class="fame-ul-li-left">
                <div class="fame-ul-li-left-img">
                  <img :src="imgURL+item.thumb">
                </div>
                <div class="fame-ul-li-left-box">
                  <div class="fame-ul-li-left-box-name">{{item.title}}</div>
                  <div class="fame-ul-li-left-box-zhicheng">
                    <div class="fame-ul-li-left-box-zhicheng-img">
                      <img src="../../assets/img/periodicals/zhiwei.png">
                    </div>
                    <div class="fame-ul-li-left-box-zhicheng-name">
                      {{item.subtitle}}</div>
                  </div>
                  <div class="fame-ul-li-left-box-yuanshi">
                    {{item.description}}
                  </div>
                </div>
              </div>
              <div
                class="fame-ul-li-right"
                @click="Honour(item)"
              >
                <div class="fame-ul-li-right-i">
                  <i
                    v-if="item.showDetail"
                    class="el-icon-arrow-up"
                  ></i>
                  <i
                    v-else
                    class="el-icon-arrow-down"
                  ></i>
                </div>
                <div class="fame-ul-li-right-xiang">{{$t('i18n.Details')}}</div>
              </div>
            </div>
            <!-- //!11111111111111111111 -->
            <div v-if="item.showDetail">
              <div
                class="html-text"
                v-html="item.detailContent"
              ></div>
            </div>
            <!-- //!11111111111111111111 -->
          </li>
        </ul>
        <!-- 主编论坛嘉宾 -->

        <div
          class="general"
          style="padding-top:10px;"
        >
          <div class="general-heng"></div>
          <div class="general-name">{{$t('i18n.EditorInChiefForumGuests')}}:</div>
        </div>
        <ul class="fame-ul">
          <li
            v-for="(item,index) in list2"
            :key="index"
          >
            <div class="fame-ul-li">
              <div class="fame-ul-li-left">
                <div class="fame-ul-li-left-img">
                  <img :src="imgURL+item.thumb">
                </div>
                <div class="fame-ul-li-left-box">
                  <div class="fame-ul-li-left-box-name">{{item.title}}</div>
                  <div class="fame-ul-li-left-box-zhicheng">
                    <div class="fame-ul-li-left-box-zhicheng-img">
                      <img src="../../assets/img/periodicals/zhiwei.png">
                    </div>
                    <div class="fame-ul-li-left-box-zhicheng-name">
                      {{item.subtitle}}</div>
                  </div>
                  <div class="fame-ul-li-left-box-yuanshi">
                    {{item.description}}
                  </div>
                </div>
              </div>
              <div
                class="fame-ul-li-right"
                @click="EditorinChief(item,index)"
              >

                <div class="fame-ul-li-right-i">
                  <i
                    v-if="item.showDetail"
                    class="el-icon-arrow-up"
                  ></i>
                  <i
                    v-else
                    class="el-icon-arrow-down"
                  ></i>
                </div>
                <div class="fame-ul-li-right-xiang">{{$t('i18n.Details')}}</div>
              </div>

            </div>
            <!-- //!11111111111111111111 -->
            <div v-if="item.showDetail">
              <div
                class="html-text"
                v-html="item.detailContent"
              ></div>
            </div>
            <!-- //!11111111111111111111 -->
          </li>
        </ul>

        <!-- 英文期刊主编（国内） -->
        <div
          class="general"
          style="padding-top:10px;"
        >
          <div class="general-heng"></div>
          <div class="general-name">{{$t('i18n.JournalEditorInChiefDomestic')}}:</div>
        </div>
        <ul class="fame-ul">
          <li
            v-for="(item,index) in list3"
            :key="index"
          >
            <div class="fame-ul-li">
              <div class="fame-ul-li-left">
                <div class="fame-ul-li-left-img">
                  <img :src="imgURL+item.thumb">
                </div>
                <div class="fame-ul-li-left-box">
                  <div class="fame-ul-li-left-box-name">{{item.title}}</div>
                  <div class="fame-ul-li-left-box-zhicheng">
                    <div class="fame-ul-li-left-box-zhicheng-img">
                      <img src="../../assets/img/periodicals/zhiwei.png">
                    </div>
                    <div class="fame-ul-li-left-box-zhicheng-name">
                      {{item.subtitle}}</div>
                  </div>
                  <div class="fame-ul-li-left-box-yuanshi">
                    {{item.description}}
                  </div>
                </div>
              </div>
              <div
                class="fame-ul-li-right"
                @click="Consultant(item)"
              >
                <div class="fame-ul-li-right-i">
                  <i
                    v-if="item.showDetail"
                    class="el-icon-arrow-up"
                  ></i>
                  <i
                    v-else
                    class="el-icon-arrow-down"
                  ></i>
                </div>
                <div class="fame-ul-li-right-xiang">{{$t('i18n.Details')}}</div>
              </div>
            </div>
            <!-- //!11111111111111111111 -->
            <div v-if="item.showDetail">
              <div
                class="html-text"
                v-html="item.detailContent"
              ></div>
            </div>
            <!-- //!11111111111111111111 -->
          </li>
        </ul>
        <!-- 国内演讲嘉宾 -->
        <div
          class="general"
          style="padding-top:10px;"
        >
          <div class="general-heng"></div>
          <div class="general-name">
            {{$t('i18n.Domesticguests')}}:</div>
        </div>
        <ul class="fame-ul">
          <li
            v-for="(item,index) in list4"
            :key="index"
          >
            <div class="fame-ul-li">
              <div class="fame-ul-li-left">
                <div class="fame-ul-li-left-img">
                  <img :src="imgURL+item.thumb">
                </div>
                <div class="fame-ul-li-left-box">
                  <div class="fame-ul-li-left-box-name">{{item.title}}</div>
                  <div class="fame-ul-li-left-box-zhicheng">
                    <div class="fame-ul-li-left-box-zhicheng-img">
                      <img src="../../assets/img/periodicals/zhiwei.png">
                    </div>
                    <div class="fame-ul-li-left-box-zhicheng-name">
                      {{item.subtitle}}</div>
                  </div>
                  <div class="fame-ul-li-left-box-yuanshi">
                    {{item.description}}
                  </div>
                </div>
              </div>
              <div
                class="fame-ul-li-right"
                @click="BasicEditor(item,index)"
              >

                <div class="fame-ul-li-right-i">
                  <i
                    v-if="item.showDetail"
                    class="el-icon-arrow-up"
                  ></i>
                  <i
                    v-else
                    class="el-icon-arrow-down"
                  ></i>
                </div>
                <div class="fame-ul-li-right-xiang">{{$t('i18n.Details')}}</div>
              </div>

            </div>
            <!-- //!11111111111111111111 -->
            <div v-if="item.showDetail">
              <div
                class="html-text"
                v-html="item.detailContent"
              ></div>
            </div>
            <!-- //!11111111111111111111 -->
          </li>
        </ul>
      </div>

      <!-- 左 -->
      <!-- 右 -->
      <about />
      <!-- 右 -->

    </div>
  </div>
</template> 

<script>
import about from './about.vue'//右侧  
export default {
  name: 'aboutMembers',
  data () {
    return {
      imgURL: '',
      aboutMembersObj: {},
      list: {},
      list1: {},
      list2: {},
      listIndex: -1,
      listShow: false,
      detailCache: new Map(),
      list1:[],
      list2:[],
      list3:[],
      list4:[]
    }
  },
  components: {
    about
  },
  created () {
    this.imgURL = localStorage.getItem('imgURL')
    this.getaboutLsit()
  },
  methods: {
    //获取主编列表
    async getaboutLsit () {
      const { data: data } = await this.$http.get('editor', {
        params: {
          cate_id: '/aboutMembers',
          lang: localStorage.getItem('Language')//切换中英文  
        }
      })
      this.list1=data.data.list1.reverse()
      this.list2=data.data.list2.reverse()
      this.list3=data.data.list3.reverse()
      this.list4=data.data.list4.reverse()
      this.aboutMembersObj = data.data

    },
    //名誉   
    async Honour (item) {
      let data = await this.getDetailContentWithCache(item.id);
      this.$set(item, "detailContent", data);
      item.showDetail = this.$set(item, "showDetail", !item.showDetail);
    },
    //主编辑  
    async EditorinChief (item, index) {
      //预写缓存
      let data = await this.getDetailContentWithCache(item.id);
      this.$set(item, "detailContent", data);
      item.showDetail = this.$set(item, "showDetail", !item.showDetail);
    },
    //通用缓存数据 
    async getDetailContentWithCache (id) {
      let detailContent = this.detailCache.get(id);
      if (detailContent) {
        //缓存中存在
        return detailContent;
      }

      const { data: data } = await this.$http.get('editor_detail', {
        params: {
          id: id,
          lang: localStorage.getItem('Language')//切换中英文  
        }
      })

      //存入缓存
      this.detailCache.set(id, data.data.contentEditor.content)
      console.log("http fetch data", this.detailCache.get(id));
      return this.detailCache.get(id);
    },
    //顾问  
    async Consultant (item) {
      //预写缓存
      let data = await this.getDetailContentWithCache(item.id);
      this.$set(item, "detailContent", data);
      item.showDetail = this.$set(item, "showDetail", !item.showDetail);
    },
    //基础主编
    async BasicEditor (item) {
      //预写缓存
      let data = await this.getDetailContentWithCache(item.id);
      this.$set(item, "detailContent", data);
      item.showDetail = this.$set(item, "showDetail", !item.showDetail);
    }
  }
}

</script>
<style lang="less" scoped>
.aboutMembers {
  padding: 0 190px;
  // max-width: 1920px;

  .contents {
    max-width:1540px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    margin-right:40px;
    padding: 45px 0;
    margin:0 auto;
    .contents-left {
      max-width: 1090px;
      background-color: #fff;
      padding-right: 52px;
      // border-right: 1px solid #e8e8e8;
      .general {
        display: flex;
        align-items: center;
        margin-bottom: 19px;
        .general-heng {
          width: 5px;
          height: 18px;
          background-color: #0647c7;
          margin-right: 7px;
        }
        .general-name {
          font-size: 18px;
          color: #363636;
          font-weight: 600;
        }
      }
      //-----
      .fame-ul {
        .fame-ul-li {
          padding: 24px 28px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #f8f8f8;
          border-radius: 11px;
          margin-bottom: 30px;
          flex-shrink: 0;
          max-width:1037px;
          .fame-ul-li-left {
            display: flex;
            align-items: center;
            .fame-ul-li-left-img {
              max-width: 131px;
              max-height: 131px;
              margin-right: 25px;
              img{
                width:100%;
                height:100%;
              }
            }
            .fame-ul-li-left-box {
              .fame-ul-li-left-box-name {
                font-size: 18px;
                color: #363636;
                font-weight: 600;
                margin-bottom: 18px;
              }
              .fame-ul-li-left-box-zhicheng {
                display: flex;
                align-items: center;
                margin-bottom: 23px;
                .fame-ul-li-left-box-zhicheng-img {
                  width: 20px;
                  height: 20px;
                  margin-top: 3px;
                }
                .fame-ul-li-left-box-zhicheng-name {
                  font-size: 16px;
                  color: #363636;
                }
              }
              .fame-ul-li-left-box-yuanshi {
                max-width: 700px;
                font-size: 14px;
                color: #2d2d2d;
                line-height: 1.5;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              }
            }
          }

          .fame-ul-li-right {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #0647c7;
            cursor: pointer;
            margin-left:5px;
            flex-shrink: 0;
            .fame-ul-li-right-xiang {
              margin-left: 4px;
            }
            .fame-ul-li-right-i {
              font-size: 14px;
            }
          }
        }
        .html-text {
          line-height: 2;
          padding: 0 20px;
          margin-bottom: 90px;
        }
      }
      //
    }
  }
}
</style> 