<template>
  <div class="unitedStates">
    <head-bgc />
    <div class="contents">
      <!-- 左 -->
      <div class="contents-left">
        <div class="contents-left-box">
          <div class="contents-left-box-gang">-</div>
          <div class="ontents-left-box-head">{{programsObj.title}}</div>
          <div class="ontents-left-box-text">
            <div v-html="programsObj.contentSingle.content"></div>
          </div>
        </div>
        <div class="contents-left-img">
          <img src="../../assets/img/theBrainProject/meiguo-bgc.png">
        </div>
      </div>
      <!-- 左 -->

      <!-- 右 -->
      <div class="contents-right">
        <div class="contents-right-head">
          <i class="el-icon-document"></i>
          <div class="contents-right-head-text">
            {{$t('i18n.Informationrecommendation')}}</div>
        </div>
        <ul class="contents-right-ul">
          <li
            class="contents-right-ul-li"
            v-for="(item,index) in list"
            :key="index"
            @click="Details(item)"
          >
            <div class="contents-right-ul-li-img">
              <img :src="imgURL+item.thumb">
            </div>
            <div class="contents-right-ul-li-box">
              <div class="contents-right-ul-li-box-name">
                {{item.description}}
              </div>
              <div class="contents-right-ul-li-box-time">{{item.create_time}}
              </div>
            </div>
          </li>
          <div
            class="contents-right-gengduo"
            @click="Readmore"
          >
            <div class="contents-right-gengduo-name">
              {{$t('i18n.Viewmore')}}
            </div>
            <div class="contents-right-gengduo-img">
              <img src="../../assets/img/theBrainProject/gengduo.png">
            </div>
          </div>
        </ul>
      </div>
      <!-- 右 -->

    </div>
  </div>
</template>
   
<script>
import headBgc from './headBgc.vue'   //导航 
export default {
  name: 'unitedStates',
  components: {
    headBgc
  },
  data () {
    return {
      imgURL: "",
      list: [],
      programsObj: {
        contentSingle: {}
      }
    }
  },
  created () {
    this.imgURL = localStorage.getItem('imgURL')
    this.getPrograms()
    this.getInformation()
  },
  methods: {
    //获取脑库列表 
    async getPrograms () {
      const { data: data } = await this.$http.get('single', {
        params: {
          cate_id: `/unitedStates`,
          lang: localStorage.getItem('Language')//切换中英文  
        }
      })
      this.programsObj = data.data
    },
    //资讯推荐
    async getInformation () {
      const { data: data } = await this.$http.get('/news', {
        params: {
          cate_id: '/newsListHotspots',
          lang: localStorage.getItem('Language')//切换中英文 
        }
      })
      this.list = data.data
    },
    //查看详情 
    Details (item) {
      this.$router.push({
        path: 'newsListItem',
        query: {
          id: item.id
        }
      })
    },
    //查看更多
    Readmore () {
      this.$router.push('newsListHotspots')
    }
  }
}

</script>
<style lang="less" scoped>
.unitedStates {
  .contents {
    display: flex;
    justify-content: space-between;
    padding: 50px 190px;
    background-color: #fff;
    .contents-left {
      position: relative;
      display: flex;
      .contents-left-box {
        width: 837px;
        height: 470px;
        padding: 40px 30px;
        padding: 40px 80px 40px 30px;
        border-radius: 8px;
        background-color: #f7f8fa;
        .contents-left-box-gang {
          width: 54px;
          height: 8px;
          background-color: #0647c7;
          margin-bottom: 20px;
        }
        .ontents-left-box-head {
          font-size: 34px;
          color: #393939;
          font-weight: 600;
          margin-bottom: 60px;
        }
        .ontents-left-box-text {
          line-height: 2;
          color: #393939;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 8;
          overflow: hidden;
        }
      }
      .contents-left-img {
        position: relative;
        top: 23px;
        right: 50px;
        width: 393px;
        height: 388px;
      }
    }

    //右
    .contents-right {
      width: 340px;
      .contents-right-head {
        font-size: 24px;
        color: #fe4c49;
        display: flex;
        align-items: center;
        .contents-right-head-text {
          font-size: 18px;
          color: #393939;
          font-weight: 600;
          margin-left: 3px;
        }
      }
      .contents-right-ul {
        width: 100%;
        background-color: #f7f8fa;
        margin-top: 20px;
        padding: 20px 15px;
        border-radius: 5px;
        .contents-right-ul-li {
          display: flex;
          margin-bottom: 45px;
          cursor: pointer;
          .contents-right-ul-li-img {
            width: 108px;
            height: 61px;
          }
          .contents-right-ul-li-box {
            width: 187px;
            margin-left: 20px;
            line-height: 1.5;
            .contents-right-ul-li-box-time {
              font-size: 13px;
              color: #c9c9c9;
              text-align: right;
              margin-top: 10px;
            }
            .contents-right-ul-li-box-name {
              font-size: 13px;
              color: #393939;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }
        }

        .contents-right-gengduo {
          display: flex;
          align-items: center;
          justify-content: center;
          border-top: 1px solid #e6e6e6;
          padding-top: 10px;
          cursor: pointer;
          .contents-right-gengduo-name {
            font-size: 13px;
            color: #e06c55;
          }
          .contents-right-gengduo-img {
            width: 10px;
            height: 10px;
            margin-left: 7px;
            margin-top: -13px;
          }
        }
      }
    }
  }
}
</style> 