<template>
  <div class="humanBrainBank">
    <div class="head-bgc">
      <img src="../../assets/img/humanBrainBank/bgc_1.png">
    </div>

    <div class="contents">
      <!-- 北京 -->
      <div
        class="contents-Beijing"
        v-if="humanBrainBankObj[0]"
      >
        <div class="contents-head">
          <div class="contents-head-box"></div>
          <div class="contents-head-text">
            {{humanBrainBankObj[0].title}}
          </div>
        </div>
        <div class="contents-Beijing-box">
          <div class="contents-Beijing-box-left">
            <div class="contents-Beijing-box-left-text">
              <div
                class="html1"
                v-html="humanBrainBankObj[0].contentBase.content"
              ></div>
            </div>
            <!-- <div
              class="contents-Beijing-box-left-gengduo"
              @click="oneClick(humanBrainBankObj[0].jumplink)"
            >
              <div class="contents-Beijing-box-left-gengduo-name">
                {{$t('i18n.Learnmore')}}
              </div>
              <div class="contents-Beijing-box-left-gengduo-i">
                <i class="el-icon-d-arrow-right"></i>
              </div>
            </div> -->
          </div>
          <div class="contents-Beijing-box-right">
            <img src="../../assets/img/humanBrainBank/1_1.png">
          </div>
        </div>
      </div>
      <!-- 北京 -->

      <!-- 健康疾病 -->
      <div
        class="contents-health"
        v-if="humanBrainBankObj[1]"
      >
        <div class="contents-head">
          <div class="contents-head-box"></div>
          <div class="contents-head-text">
            {{humanBrainBankObj[1].title}}
          </div>
        </div>
        <div class="contents-health-box">
          <div class="contents-health-box-left">
            <img src="../../assets/img/humanBrainBank/jibing-z.png">
          </div>
          <div class="contents-health-box-right">
            <!-- <div class="contents-health-box-right-img">
              <img src="../../assets/img/humanBrainBank/jibing-y_1.png">
            </div> -->
            <div class="contents-health-box-right-text">
              <div class="contents-health-box-right-text-name">
                <div class="contents-health-box-right-text-name-yincang">
                  <div
                    class="html2"
                    v-html=" humanBrainBankObj[1].contentBase.content"
                  ></div>
                </div>
              </div>
              <!-- <div
                class="contents-health-box-right-text-gengduo"
                @click="twoClick(humanBrainBankObj[1].jumplink)"
              >
                <div>{{$t('i18n.Learnmore')}}</div>
                <div> <i class="el-icon-d-arrow-right"></i></div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- 健康疾病 -->
      <!-- TODO: 科学家论坛去掉最后一个版块 -->
      <!-- 安徽 -->
      <!-- <div
        class="contents-anhui"
        v-if="humanBrainBankObj[2]"
      >
        <div class="contents-head">
          <div class="contents-head-box"></div>
          <div class="contents-head-text">
            {{humanBrainBankObj[2].title}}
          </div>
        </div>
        <div class="contents-anhui-box">
          <div class="contents-anhui-box-left">
            <img src="../../assets/img/humanBrainBank/anhui-bgc.png">
          </div>

          <div class="contents-anhui-box-left-right">
            <div class="contents-anhui-box-left-right-box">
              <div class="contents-anhui-box-left-right-box-text">
                <div
                  class="html3"
                  v-html=" humanBrainBankObj[2].contentBase.content"
                ></div>
              </div>
              <div
                class="contents-anhui-box-left-right-box-gengduo"
                @click="threeClick(humanBrainBankObj[1].jumplink)"
              >
                <div>{{$t('i18n.Learnmore')}}</div>
                <div> <i class="el-icon-d-arrow-right"></i></div>
              </div>
            </div>

            <div class="contents-anhui-box-left-left">
              <img src="../../assets/img/humanBrainBank/anhui-r.png">
            </div>
          </div>
        </div>
      </div> -->
      <!-- 安徽 -->

    </div>
  </div>
</template> 
 
<script>
export default {
  name: 'humanBrainBank',
  data () {
    return {
      humanBrainBankObj: []
    }
  },
  created () {
    this.getHumanBrainBank()
  },
  methods: {
    //获取列表
    async getHumanBrainBank () {
      const { data: data } = await this.$http.get('brainpools', {
        params: {
          cate_id: '/humanBrainBank',
          lang: localStorage.getItem('Language')//切换中英文  
        }
      })
      this.humanBrainBankObj = data.data
    },
    //点击1 
    oneClick (item) {
      window.open(item)
    },
    //点击2
    twoClick (item) {
      window.open(item)
    },
    //点击3
    threeClick (item) {
      window.open(item)
    },
  }
}

</script> 
<style lang="less" scoped>
.humanBrainBank {
  max-width: 100%;
  .head-bgc {
    max-width: 100%;
    max-height: 133px;
    img{
      width:100%;
      height:auto;
    }
  }
  .contents {
    position: relative;
    background-color: #fff;
    // padding: 0 190px;
    max-width:1580px;
    padding:0 20px;
    margin:0 auto;
    overflow: hidden;
    .contents-head {
      position: relative;
      top: -15px;
      left: 0;
      margin-bottom: 87px;
      .contents-head-box {
        position: absolute;
        width: 80px;
        height: 50px;
        background-color: #6E9FFF;
      }
      .contents-head-text {
        position: relative;
        top: 32px;
        left: 30px;
        color: #363636;
        font-size: 22px;
        font-weight: 600;
      }
    }

    //北京
    .contents-Beijing {
      position: relative;
      margin-bottom: 100px;
      // max-width:1540px;
     
      .contents-Beijing-box {
        display: flex;
        background-color: #f7f8fa;
        max-width:1540px;
        height:auto;
        margin:0 auto;
        .contents-Beijing-box-left {
          max-width: 1130px;
          padding: 33px 40px;
          .contents-Beijing-box-left-text {
            line-height: 2;
            margin-bottom: 50px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 7;
            overflow: hidden;
          }
          .contents-Beijing-box-left-gengduo {
            display: flex;
            justify-content: flex-end;
            align-content: center;
            cursor: pointer;
            .contents-Beijing-box-left-gengduo-name {
              color: #0647c7;
              margin-right: 7px;
            }
            .contents-Beijing-box-left-gengduo-i {
              color: #0647c7;
            }
          }
        }
        .contents-Beijing-box-right {
          max-width: 391px;
          max-height: 382px;
          // height:auto;
          img{
            width:auto;
            height:100%;
          }
        }
      }

      // --
    }

    //国家健康和疾病人脑组织资源库
    // .contents-health {
    //   position: relative;
    //   margin-bottom: 130px;

    //   .contents-health-box {
    //     display: flex;
    //     max-width:1540px;
    //     max-height:541px;
    //     margin:0 auto;
    //     position: relative;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     .contents-health-box-left {
    //       position: relative;
    //       left:45px;
    //       top:0px;
    //       z-index: 9999;
    //       max-width: 468px;
    //       max-height: 262px;
    //       img{
    //         width:100%;
    //         height:auto;
    //       }
    //     }
    //     .contents-health-box-right {
    //       position: relative;
    //       max-width: 1055px;
    //       max-height: 541px;
    //       .contents-health-box-right-img {
    //         max-width: 1055px;
    //         max-height: 541px;
    //         img{
    //           width:100%;
    //           height:100%;
    //         }
    //       }
    //       .contents-health-box-right-text {
    //         position: absolute;
    //         top: 80px;
    //         left: 133px;
    //         z-index: 999;
    //         .contents-health-box-right-text-name {
    //           max-width: 830px;
    //           max-height: 380px;
    //           .contents-health-box-right-text-name-yincang {
    //             color: #fff;
    //             display: -webkit-box;
    //             -webkit-box-orient: vertical;
    //             -webkit-line-clamp: 11;
    //             overflow: hidden;
    //             line-height: 2;
    //           }
    //         }
    //         .contents-health-box-right-text-gengduo {
    //           display: flex;
    //           justify-content: flex-end;
    //           align-content: center;
    //           color: #fff;
    //           cursor: pointer;
    //           i {
    //             margin-left: 7px;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    .contents-health {
      position: relative;
      margin-bottom: 130px;

      .contents-health-box {
        display: flex;
        max-width:1540px;
        max-height:541px;
        margin:0 auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .contents-health-box-left {
          position: relative;
          left:40px;
          top:0px;
          z-index: 9999;
          max-width: 468px;
          max-height: 262px;
          img{
            width:100%;
            height:100%;
          }
        }
        .contents-health-box-right {
          position: relative;
          max-width: 1055px;
          max-height: 541px;
          background:url('../../assets/img/humanBrainBank/jibing-y_1.png') no-repeat center;
          background-size:100% 100%;
          // .contents-health-box-right-img {
          //   max-width: 1055px;
          //   max-height: 541px;

          //   // img{
          //   //   width:100%;
          //   //   height:100%;
          //   // }
          // }
          .contents-health-box-right-text {
            padding:80px 60px 80px 133px;
            // position: absolute;
            // top: 80px;
            // left: 133px;
            // z-index: 999;
            .contents-health-box-right-text-name {
              max-width: 830px;
              min-width:351px;
              max-height: 380px;
              .contents-health-box-right-text-name-yincang {
                color: #fff;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 11;
                overflow: hidden;
                line-height: 2;
              }
            }
            .contents-health-box-right-text-gengduo {
              display: flex;
              justify-content: flex-end;
              align-content: center;
              color: #fff;
              cursor: pointer;
              i {
                margin-left: 7px;
              }
            }
          }
        }
      }
    }
    // //安徽医科大学分库
    // .contents-anhui-box {
    //   position: relative;
    //   padding-bottom: 120px;
    //   .contents-anhui-box-left {
    //     width: 803px;
    //     height: 230px;
    //     position: absolute;
    //     top: 140px;
    //     left: -190px;
    //   }

    //   .contents-anhui-box-left-right {
    //     position: relative;
    //     left: 30px;
    //     top: 0;
    //     width: 1513px;
    //     height: 338px;
    //     background-color: #f7f8fa;
    //     display: flex;

    //     .contents-anhui-box-left-right-box {
    //       padding: 70px 100px 28px 90px;
    //       width: 1130px;
    //       .contents-anhui-box-left-right-box-text {
    //         height: 165px;
    //         line-height: 2;
    //         display: -webkit-box;
    //         -webkit-box-orient: vertical;
    //         -webkit-line-clamp: 7;
    //         overflow: hidden;
    //       }
    //       .contents-anhui-box-left-right-box-gengduo {
    //         cursor: pointer;
    //         display: flex;
    //         justify-content: flex-end;
    //         align-content: center;
    //         margin-top: 50px;
    //         color: #0647c7;
    //         i {
    //           margin-left: 7px;
    //         }
    //       }
    //     }

    //     .contents-anhui-box-left-left {
    //       width: 368px;
    //       height: 338px;
    //     }
    //   }
    // }

    // .html1 {
    //   display: -webkit-box;
    //   -webkit-box-orient: vertical;
    //   -webkit-line-clamp: 10;
    //   overflow: hidden;
    // }

    // .html2 {
    //   display: -webkit-box;
    //   -webkit-box-orient: vertical;
    //   -webkit-line-clamp: 11;
    //   overflow: hidden;
    // }

    // .html3 {
    //   display: -webkit-box;
    //   -webkit-box-orient: vertical;
    //   -webkit-line-clamp: 5;
    //   overflow: hidden;
    // }
  }
}
</style>      