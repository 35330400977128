import VueI18n from 'vue-i18n'
import Vue from 'vue'
Vue.use(VueI18n)

// 引入需要语言包也可是js文件，export default抛出语言包的对象
import en from './langs/en'
import zh from './langs/zh'

Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: "zh", //语言标识
    messages: { //配置多语言 根据自己的项目需求  这里配置了中文和英文
        'zh-cn': zh, //中文 
        'en': en, //英文   
    },
    fallbackLocale: "zh-cn", //没有英文的时候默认 中文语言
    silentTranslationWarn: true,
})
export default i18n