<template>
  <div class="contents">
    <div class="tabs">
      <div class="title">
          {{$t('i18n.WebsiteStatement')}}
      </div>
      <div class="straight-line">
          <div class="straight-line-k"></div>
          <div class="straight-line-x"></div>
      </div> 
      <ul>
      <li v-for="(item,index ) in statements" 
          :key="index" @click="changeTab(item,index)"  
          :class="index==tabsIndex?'tabs-active':''"
      >
        {{ item.title }}
      </li>
      <!-- <li>{{$t('AllDisclaimersApply')}}</li>
      <li>{{$t('IntellectualPropertyRights')}}</li> -->
    </ul>
    </div>

    <div class="web-con">
       <div class="apply" v-for="(item,index) in statecontent" :key="index" v-show="index===tabsIndex">
        <div class="tit">{{ item.title }}</div>
        <div class="con">
          <p v-for="(item,index) in item.contents" :key="index">{{ item }}</p>
        </div>
       </div>
    </div>
  </div>
</template>
<script>
import { toRefs,reactive, isReactive } from 'vue'

export default{
  data(){
    return{
      statements:[
        {title:this.$t('i18n.AllDisclaimersApply'),isActive:false},
        {title:this.$t('i18n.IntellectualPropertyRights'),isActive:false}
      ],
      statecontent:[
        { 
          title:this.$t('i18n.AllDisclaimersApply'),
          contents:  this.handleSection(this.$t('i18n.ApplyStatement'))
        },
        {
          title:this.$t('i18n.IntellectualPropertyRights'),        
          contents: this.handleSection(this.$t('i18n.RightsStatement'))
        }
      ],
      tabsIndex: 0
    }
  },
  created(){
  },
  mounted(){
    this.tabsIndex = this.$route.query.index * 1 
  },
  watch: {
        //监听路由地址的改变
     $route:{
       immediate:true,
       handler(){
          this.tabsIndex = this.$route.query.index * 1
       }
     }
  },
  methods:{
    changeTab(item,index){
      this.tabsIndex = index
      this.$router.push({path:'/websiteStatement',query:{index:index}})
      // this.tabsIndex = this.$route.query.index * 1
    },
    handleSection(contents){
      return contents.split('/n')
    },
  }
}
</script>
<style lang="less" scoped>
.contents{
  padding:0 190px;
  margin-top:52px;
  display: flex;
  .tabs{

  }
  ul{
    margin-top:33px;
    font-size:15px;
    li{
      width: 411px;
      height: 66px;
      border-bottom:1px solid #DBDBDB;
      padding-left:16px;
      line-height:66px;
      // &:hover{
      //   background: #F7FAFD;
      //   color:#0647c7;
      // }
    }
    .tabs-active{
      background: #F7FAFD;
      color:#0647c7;
    }
  }
  
  .web-con{
    margin-left:63px;
    .title{
      font-size:34px;
      color:#393939;
    }
    .con{
      margin:58px 0 72px 0px;
      color:#2D2D2D;
      font-size: 14px;
      p{
        margin-bottom:42px;
      }
    }
  }
}
  .tit {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .straight-line {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    .straight-line-k {
      width: 47px;
      height: 6px;
      background-color: #0647C7;
    }
    .straight-line-x {
      width: 411px;
      height: 1px;
      background-color: #DBDBDB;
    }
  }
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}

.el-tabs--right .el-tabs__content,
.el-tabs--left .el-tabs__content {
  height: 100%;
}
</style>