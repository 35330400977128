<template>
  <div class="about">
    <div class="contents">

      <div class="contents-box-right-head">
        <div class="contents-box-right-head-box">
          <img src="../../assets/img/academicConferences/tushu_1.png">
        </div>
        <div class="contents-box-right-head-text">
          {{$t('i18n.Periodicals')}}
        </div>
      </div>

      <div class="contents-box">

        <div class="contents-box-pad">
          <div class="contents-img">
            <img :src="imgURL+periodicalsObj.thumb">
          </div>
        </div>

        <div
          class="contents-box-img-text"
          v-if="periodicalsObj.contentSingle"
        >
          <div class="contents-box-img-heng"></div>
          <div class="contents-box-img-name">
            <div v-html="periodicalsObj.contentSingle.content"></div>
          </div>
          <div
            class="contents-box-img-yuedu"
            @click="readAll(periodicalsObj.id)"
          >【{{$t('i18n.Readall')}}】</div>
        </div>

        <div class="contents-box-editor">

          <div class="contents-box-editor-head">
            <div class="contents-box-editor-head-img">
              <img src="../../assets/img/periodicals/yumap.png">
            </div>
            <div class="contents-box-editor-head-naem">
              <!-- TODO:主编辑CheifEditor 改为 科学家 -->
              {{$t('i18n.Scientist')}}</div>
            <div class="contents-box-editor-head-kuan"></div>
            <div class="contents-box-editor-head-xi"></div>
          </div>

          <ul class="contents-box-editor-ul">
            <li
              class="contents-box-editor-ul-li"
              v-for="(item,index) in LordList"
              :key="index"
            >
              <div class="contents-box-editor-ul-li-box">

                <div class="contents-box-editor-ul-li-box-i">
                  <i class="el-icon-user-solid"></i>
                </div>
                <div class="contents-box-editor-ul-li-box-name">{{item.title}}
                </div>
              </div>
              <div class="contents-box-editor-ul-li-box-text">
                {{item.description}}
              </div>
              <div
                class="contents-box-editor-ul-li-box-xiang"
                @click="Details(item)"
              >
                <div class="contents-box-editor-ul-li-box-xiang-name">
                  {{$t('i18n.Details')}}</div>
                <div class="contents-box-editor-ul-li-box-xiang-i">
                  <i class="el-icon-d-arrow-right"></i>
                </div>
              </div>
            </li>
          </ul>
          <div
            class="contents-box-editor-btn"
            @click="editorBtn()"
          >
          <!-- 查看编辑组 -->
            {{$t('i18n.Viewtheeditorialteam')}}
          </div>
        </div>

      </div>
    </div>
  </div>
</template>   

<script>    

export default {
  name: 'about',
  data () {
    return {
      imgURL: '',
      LordList: [],
      periodicalsObj: {}
    }
  },
  created () {
    this.imgURL = localStorage.getItem('imgURL')
    this.getLordList()
    this.getSingle()
  },
  methods: {
    //查看编辑
    editorBtn () {
      this.$router.push({
        path: 'aboutMembers'
      })
    },
    //获取右下角主编辑
    async getLordList () {
      const { data: data } = await this.$http.get('editor', {
        params: {
          cate_id: '/aboutMembers',
          lang: localStorage.getItem('Language')//切换中英文  
        }
      })
      this.LordList = data.data.list1.slice(0,2).reverse()
    },
    //获取期刊
    async getSingle () {
      const { data: data } = await this.$http.get('single', {
        params: {
          lang: localStorage.getItem('Language'),
          cate_id: '/periodicals'
        }
      })
      this.periodicalsObj = data.data
    },
    //详情
    Details (item) {
      this.$router.push({
        path: 'expertDetails',
        query: {
          id: item.id
        }
      })
    },
    //阅读全部 
    readAll (item) {
      this.$router.push({
        path: 'periodicals',
        query: {
          id: item.id
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.about {
  max-width: 381px;
  background-color: #ffffff;
  .contents {
    border-left: 1px solid #e8e8e8;
    // padding-left:20px;
    .contents-box-right-head {
      position: relative;
      top: 0px;
      left: 40px;
      display: flex;
      align-items: center;
      margin-bottom: 31px;
      .contents-box-right-head-box {
        width: 28px;
        height: 24px;
        margin-right: 7px;
      }
      .contents-box-right-head-text {
        font-size: 18px;
        color: #363636;
        font-weight: 600;
      }
    }

    // 内容
    .contents-box {
      background-color: #f9fcff;
      padding-bottom: 10px;
      margin-left:70px;
      .contents-box-pad {
        padding: 0 38px;
        .contents-img {
          max-width: 306px;
          max-height: 354px;
        }
      }
      .contents-box-img-text {
        padding: 0 38px;
        margin: 50px 0;
        .contents-box-img-heng {
          width: 25px;
          height: 5px;
          background-color: #0647c7;
          margin-bottom: 18px;
        }
        .contents-box-img-name {
          font-size: 14px;
          color: #2d2d2d;
          margin-bottom: 22px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 14;
          overflow: hidden;
          line-height: 1.5;
        }
        .contents-box-img-yuedu {
          font-size: 16px;
          color: #0647c7;
          text-align: right;
          cursor: pointer;
        }
      }

      .contents-box-editor {
        .contents-box-editor-head {
          position: relative;
          display: flex;
          align-items: center;
          height: 48px;
          background-color: #eaf1f8;
          .contents-box-editor-head-img {
            width: 15px;
            height: 21px;
            margin-left: 23px;
            margin-right: 5px;
          }
          .contents-box-editor-head-naem {
            color: #393939;
          }
          .contents-box-editor-head-kuan {
            position: absolute;
            bottom: -4px;
            left: 44px;
            width: 30px;
            height: 5px;
            background-color: #0647c7;
            z-index: 99;
          }
          .contents-box-editor-head-xi {
            position: absolute;
            bottom: -2px;
            left: 24px;
            width: 123px;
            height: 1px;
            background-color: #21201f;
          }
        }

        .contents-box-editor-ul {
          padding: 0 30px;
          margin-top: 50px;
          .contents-box-editor-ul-li {
            margin-bottom: 57px;
            .contents-box-editor-ul-li-box {
              display: flex;
              align-items: center;
              margin-bottom: 12px;
              .contents-box-editor-ul-li-box-i {
                margin-right: 5px;
                font-size: 16px;
              }
              .contents-box-editor-ul-li-box-name {
                font-size: 16px;
                color: #393939;
                font-weight: 600;
              }
            }
            .contents-box-editor-ul-li-box-text {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;

              font-size: 15px;
              color: #2d2d2d;
              line-height: 1.6;
              color: #2d2d2d;
              margin-bottom: 30px;
            }
            .contents-box-editor-ul-li-box-xiang {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              cursor: pointer;
              .contents-box-editor-ul-li-box-xiang-name {
                font-size: 14px;
                color: #153a7e;
                margin-right: 5px;
              }
              .contents-box-editor-ul-li-box-xiang-i {
                font-size: 16px;
                color: #153a7e;
              }
            }
          }
        }

        .contents-box-editor-btn {
          margin-left: 31px;
          // width: 122px;
          height: 40px;
          color: #fff;
          background-color: #0647c7;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }
}
</style> 