<template>
  <div class="clinicalGuidelines">

    <div class="head">
      <div class="head-1">
        <img src="../../assets/img/clinicalGuidelines/bgc1_deep.png">
      </div>
      <div class="head-2">
        <img
          v-if="imgShow"
          src="../../assets/img/clinicalGuidelines/bgc2_deep.png"
        >
        <img
          v-else
          src="../../assets/img/clinicalGuidelines/depth-y.png"
        >
      </div>
    </div>

    <div class="input-box">
      <div class="input">
        <el-input
          :placeholder="$t('i18n.Pleaseentercontent')"
          v-model="input"
          clearable
          @change="change"
        >
        </el-input>
      </div>
      <div
        class="input-img"
        @click="changeClick"
      >
        <img src="../../assets/img/clinicalGuidelines/soushuo.png">
      </div>
    </div>

    <div class="contents">
      <!--//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
      <div
        class="box"
        ref="navigat"
      >
        <ul
          class="classfiy"
          ref="navigatUl"
        >
          <li
            v-for="(item,index) in navigatList"
            :key="index"
            @click="navigatClick(index,item)"
            :class="index===navigatIndex?'active':'' "
          >{{item.name}}</li>
          <span
            v-if="navigatIcon"
            @click="pulldownClick"
            class="xila"
          ><i class="el-icon-arrow-down"></i></span>
          <span
            v-if="highPull"
            class="shang"
            @click="highPullClick"
          ><i class="el-icon-arrow-up"></i></span>
        </ul>
      </div>
      <!-- //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
      <!-- 内容 -->
      <div class="contents-box">
        <ul
          class="contents-box-ul"
          v-if="show"
        >
          <li
            class="contents-box-ul-li"
            v-for="(item,index) in clinicalGuidelinesObj.data"
            :key="index"
            @click="readingClick(item)"
          >
            <div class="contents-box-ul-li-left">
              <div class="contents-box-ul-li-left-head">
                {{item.title}}
              </div>
              <div class="contents-box-ul-li-left-text">
                {{item.description}}
              </div>
              <div class="contents-box-ul-li-left-time">
                {{item.create_time_text}}</div>
            </div>
            <div
              class="contents-box-ul-li-right"
            >{{$t('i18n.Readthefullarticle')}}</div>
          </li>
          <div class="page">
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="currentchange"
              @prev-click="prevClick"
              @next-click="nextClick"
              :current-page.sync="page"
              hide-on-single-page
              :page-count="clinicalGuidelinesObj.last_page"
            >
            </el-pagination>
          </div>
        </ul>
        <div
          class="show"
          v-if="!show"
        >
          <el-empty description="暂无数据"></el-empty>
        </div>
      </div>
      <!-- 内容 -->
    </div>

  </div>
</template>

<script>
export default {
  name: 'clinicalGuidelines',
  data () {
    return {
      input: '',
      navigationIndex: 0,
      expandIndex: 0,
      expandShow: false,
      navigatList: [],
      navigatIndex: 0,
      navigatIcon: true,
      highPull: false,
      navigatName: '',//传递给后端
      page: 1,//页码
      list_row: 5,//条数
      clinicalGuidelinesObj: {},
      show: true,
      imgShow: true,
    };
  },
  watch: {
    //监听上拉
    highPull: {
      handler (newVal, oldVal) {
        this.$nextTick(function () {
          if (this.$refs.navigat.clientHeight > 100) this.highPull = true
        })
      },
      immediate: true,
    },
  },
  created () {
    if (localStorage.getItem('Language') == 'zh-cn') {
      this.imgShow = true
    } else {
      this.imgShow = false
    }
    this.getCateList()
    this.getMedicalList()
  },
  methods: {
    //点击导航  
    navigatClick (i, item) {
      this.navigatIndex = i
      this.navigatName = item.name
      setTimeout(() => {
        this.page = 1
        this.getMedicalList()
      }, 500)
      // this.$Spin.show(); 
    },
    //上拉 
    highPullClick () {
      this.$nextTick(() => {
        if (this.$refs.navigat.clientHeight > 100) this.highPull = true
        this.$refs.navigat.style.height = `50px`
        this.navigatIcon = true
        this.highPull = false
      });
    },
    //点击导航下拉
    pulldownClick () {
      this.$nextTick(() => {
        this.$refs.navigat.style.height = `${this.$refs.navigatUl.clientHeight}px`
        this.navigatIcon = false
        this.highPull = true
      })
    },
    //决定导航是否下拉
    decidePulldown () {
      this.$nextTick(() => {
        if (this.$refs.navigat.offsetHeight < 110) {
          this.highPull = false
        }
        if (this.$refs.navigatUl.offsetHeight > 110) {
          this.navigatIcon = !this.highPull;
        }
        if (this.$refs.navigat.offsetHeight == 0) {
          this.highPull = true
        }
      });
    },
    //回车
    change () {
      this.getMedicalList()
    },
    //点击搜索
    changeClick () {
      this.getMedicalList()
    },
    //阅读全文 
    readingClick (item) {
      this.$router.push({
        path: 'clinicalGuidelinesItme',
        query: {
          id: item.id
        }
      })
    },
    //获取导航列表 
    async getCateList () {
      const { data: data } = await this.$http.get('medical_cate', {
        params: {
          lang: localStorage.getItem('Language')//切换中英文  
        }
      })
      let arr = []
      arr.push({
        id: 0,
        name: '全部',
      }, ...data.data[0].option)
      this.navigatList = arr
      setTimeout(() => {
        this.decidePulldown()
      }, 200);
    },
    //获取列表
    async getMedicalList () {
      const { data: data } = await this.$http.get('medical', {
        params: {
          cate_id: '/clinicalGuidelines',
          search_title: this.input,  //this.navigatName
          search_cate: this.navigatName,  //this.input
          page: this.page,
          list_rows: this.list_row,
          lang: localStorage.getItem('Language')//切换中英文  
        }
      })
      this.clinicalGuidelinesObj = data.data

      if (this.clinicalGuidelinesObj.data.length == 0) {
        this.show = false
      } else {
        this.show = true
      }
    },
    //点击每一页 
    currentchange (e) {
      this.page = e
      setTimeout(() => {
        this.getMedicalList()
      }, 500)
      // this.$Spin.show(); 
    },
    //上一页  
    prevClick (e) {
      this.page = e
      setTimeout(() => {
        this.getMedicalList()
      }, 500)
      // this.$Spin.show();
    },
    //下一页 
    nextClick (e) {
      this.page = e
      setTimeout(() => {
        this.getMedicalList()
      }, 500)
      // this.$Spin.show();
    },
  }
}

</script>
<style lang="less" scoped>
.clinicalGuidelines {
  .head {
    position: relative;
    .head-1 {
      width: 100%;
      height: 230px;
      position: relative;
    }
    .head-2 {
      width: 719px;
      height: 44px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .input-box {
    position: relative;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    width: 565px;
    height: 60px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    /deep/ .el-input__inner {
      border: 0;
    }
    .input {
      width: 485px;
      display: flex;
      align-items: center;
      border-right: 1px solid #d9d9d9;
    }

    .input-img {
      width: 29px;
      height: 29px;
      margin-right: 25px;
      cursor: pointer;
    }
  }

  .contents {
    position: relative;
    padding: 0 190px;

    .navigation {
      width: 100%;
      background-color: #0647c7;
      display: flex;
      align-items: center;
      border-radius: 5px;
      .navigation-item {
        padding: 25px 40px;
        color: #fff;
        cursor: pointer;
        &:hover {
          background-color: #1f3d7e;
        }
        transition: 0.4s all;
      }
    }
    .active {
      background-color: #1f3d7e;
    }
    .navigation-show {
      width: 98%;
      background-color: pink;
      position: relative;
      top: 0;
      left: 1%;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      padding: 30px 25px 60px 25px;
      .navigation-show-ul {
        display: flex;
        .navigation-show-ul-li {
          font-size: 14px;
          color: #393939;
          padding: 8px 20px;
          background-color: #f1f1f1;
          margin-right: 30px;
          border-radius: 3px;
          cursor: pointer;
          transition: 0.4s all;
          &:hover {
            background-color: #0647c7;
            color: #fff;
          }
        }
        .show-active {
          background-color: #0647c7;
          color: #fff;
        }
      }
    }

    .contents-box {
      display: flex;
      .contents-box-ul {
        margin-top: 30px;
        width: 100%;
        .contents-box-ul-li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-shadow: 0px 3px 10px 1px #ebebeb;
          border-radius: 8px;
          padding: 24px 38px;
          margin-bottom: 24px;
          cursor:pointer;
          .contents-box-ul-li-left {
            width: 1165px;
            padding-right:5px;
            .contents-box-ul-li-left-head {
              font-size: 18px;
              color: #363636;
              font-weight: 600;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
            .contents-box-ul-li-left-text {
              font-size: 14px;
              color: #999999;
              margin-top: 15px;
              margin-bottom: 30px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
            .contents-box-ul-li-left-time {
              font-size: 14px;
              color: #c5c5c5;
            }
          }
          .contents-box-ul-li-right {
            min-width: 95px;
            // width: 95px;
            padding: 0 5px;
            height: 35px;
            color: #fff;
            background-color: #0647c7;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
      .page {
        margin: 20px 0 55px 0;
        text-align: center;
        // /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        //   background-color: rgb(255, 116, 116);
        // }
        // /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
        //   color: rgb(255, 116, 116);
        // }
      }
    }

    .box {
      // padding: 20px;
      background-color: #0647c7;
      position: relative;
      height: 50px;
      overflow: hidden;
      transition: all 0.3s;
      color: #fff;
      .classfiy {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        span {
          color: #fff;
          font-size: 16px;
          line-height: 35px;
          // padding-right: 20px;
          margin: 7px 20px;
        }
        li {
          font-size: 16px;
          color: #fff;
          // margin-right: 30px;
          padding: 0 22px;
          height:50px;
          line-height:34px;
          // margin: 7px;
          // padding: 7px 18px;

          line-height: 50px;
          cursor: pointer;
          transition: all 0.3s;
        }
        .active {
          // border-radius: 4px;
          background: #5B92FF;
          color: #fff;
        }
        .xila {
          position: absolute;
          bottom: 0px;
          right: -15px;
          font-size: 22px;
          cursor: pointer;
          z-index: 999;
          // background-color: #fff;
          &:hover {
            color: #5B92FF;
          }
        }
        .shang {
          position: absolute;
          bottom: 0px;
          right: -15px;
          cursor: pointer;
          font-size: 22px !important;
          &:hover {
            color: #5B92FF;
          }
        }
      }
    }
    .show {
      margin: 160px auto;
    }
  }
}
</style>   