//轮播图
<template>
  <div class="carousel">
    <el-carousel
      trigger="click"
      height="470px"
    >
      <el-carousel-item
        v-for="(item,index) in list"
        :key="index"
      >
        <img :src="imgURL+item.image">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>  
<script> 
export default {
  name: 'carousel',
  data () {
    return {
      imgURL: '',
      list: []

    };
  },
  created () {
    this.imgURL = localStorage.getItem('imgURL')
    this.getBanner()
  },
  methods: {
    async getBanner () {
      const { data: data } = await this.$http.get('banner', {
        params: {
          lang: localStorage.getItem('Language')//切换中英文  
        }
      })
      this.list = data.data
    }
  }
}

</script>
<style lang="less" scoped>
.carousel {
  // background-color: pink;
  width: 100%;
  max-height:475px;
  /deep/ .el-carousel__container {
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>  