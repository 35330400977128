//导航
<template>
  <div>
    <!-- 导航 -->
    <div class="nav">
      <div class="nav-text"><img src="../../assets/img/clinicalGuidelines/logo.png" alt=""></div>
      <div class="nav-list">
        <el-menu
          :default-active="$route.path"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          background-color="#0647c7"
          text-color="#fff"
          active-text-color="#fff"
        >
          <template v-for="(item,index) in list">
            <el-menu-item
              v-if="item.son_child.length==0||index==1"
              :route="item.vue_router"
              :index="item.vue_router"
            >
              {{item.title}}
            </el-menu-item>
            <el-submenu
              v-else
              :index="item.vue_router"
            >
              <template slot="title">{{item.title}}</template>
              <el-menu-item
                v-for="(item2,index2) in item.son_child"
                :route="item2.vue_router"
                :index="item2.vue_router"
              >
                <a
                  class="menu-item-a"
                  v-if="item2.jump_url"
                  :href="item2.jump_url"
                >{{item2.title}}
                </a>
                <div v-else>{{item2.title}}</div>
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </div>
      <div>
        <div class="language">
          <el-select
            v-model="value"
            placeholder="请选择"
            @change='change'
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!-- 导航 -->
  </div>
</template>  
<script>
export default {
  name: 'navMenu',
  data () {
    return {
      activeIndex: '/homeContents',
      options: [{
        value: 'zh-cn',
        label: '简体中文'
      }, {
        value: 'en',
        label: 'English'
      }],
      list: [],
      value: 'zh-cn'
    };
  },
  // watch: {
  //   $route: {
  //     handler: function (to, from) {
  //     },
  //     深度观察监听   
  //     deep: true,
  //     immediate: true
  //   }  
  // }, 
  created () {
    //进入页面语言 
    if (localStorage.getItem('Language') != null && localStorage.getItem('Language') != '' && localStorage.getItem('Language') != undefined) {
      this.$i18n.locale = localStorage.getItem('Language') //切换中英文
      this.value = localStorage.getItem('Language')//切换中英文  
    } else {
      localStorage.setItem('Language', 'zh-cn'); // 存入一个值   
    }
    this.getNav()
    localStorage.setItem('imgURL', 'http://wmjforum.com'); //存入图片前缀   
  },
  methods: {
    //导航 
    async getNav () {
      const { data: data } = await this.$http.get('category', {
        params: {
          lang: localStorage.getItem('Language')
        }
      })
      this.list = data.data
    },
    //切换路由
    handleSelect (key, keyPath) {
      console.log(key,keyPath)
      this.$router.push(key)
    },
    //切换中英文
    change (e) {
      if (e == 'zh-cn') {
        this.$i18n.locale = 'zh-cn'
      } else {
        this.$i18n.locale = 'en'
      }
      localStorage.setItem('Language', this.$i18n.locale); // 存入一个值  
      location.reload()
    },
  }
}
</script>  

<style lang="less" scoped>
.nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #0647c7;
  padding:20px;
  // padding: 0 310px 0 90px;
  max-height:96px;
  font-size:16px;
  .nav-text {
    max-width: 254px;
    max-height:71px;
    display: flex;
    // flex-shrink: 0;
    img{
      width:100%;
      height:100%;
    }
  }
  .language {
    width: 110px;
    font-size:16px;
    /deep/.el-input__inner {
      background-color: #0647c7;
      color: #fff;
    }
    /deep/ .el-select .el-input .el-select__caret {
      color: #fff;
    }
  }
}
.nav-list{
  height:96px;
  font-size:16px;
  .el-menu-demo{
    height:96px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
//a标签
.menu-item-a {
  color: #fff;
  text-decoration: none;
  // height:96px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // font-size:18px;
}

/deep/.el-menu-item {
  // height:96px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:16px;
  padding:0 30px;
}
/deep/.el-submenu{
  // height:96px;
  display: flex;
  align-items: center;
  justify-content: center;

}

// 浮动
// /deep/.el-submenu__title {
//   font-size:16px;
// }
/deep/.el-menu-item:hover {
  outline: 0;
  background-color: rgba(3, 17, 26, 0.5) !important;
  height:96px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/.el-submenu__title i {
  color: #fff;
}

// 选中
.el-menu-item.is-active {
  background-color: #000 !important;
  // height:96px;
  display: flex;
  // width:100%;
  height:100%;
}
/deep/.el-menu.el-menu--horizontal {
  border-bottom: none;
  font-size: 16px;
}
//字体大小
/deep/ .el-menu--horizontal>.el-submenu .el-submenu__title{
  font-size: 16px !important;
  height:96px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>     
 