<template>
  <div class="deatils">
    <div class="deatils-head">
      <img src="../../assets/img/academicConferences/d-bgc.png">
      <div class="deatils-head-name">{{academic.title}}</div>
    </div>
    <!-- tabs -->
    <div class="deatils-tabs">
      <ul class="deatils-tabs-ul">
        <li
          class="deatils-tabs-ul-li"
          v-for="(item,index) in list"
          :key="index"
          @click="tabsClick(index)"
          :class="index==tabsIndex?'active':''"
        >
          <div class="deatils-tabs-ul-li-img">
            <img
              v-if="index!=tabsIndex"
              :src="item.img"
            >
            <img
              v-else
              :src="item.ximg"
            >
          </div>
          <div class="deatils-tabs-ul-li-name">{{item.name}}</div>
        </li>
      </ul>
    </div>
    <!-- tabs -->
    <!--背景 -->
    <div class="deatils-bgc">

      <div class="deatils-bgc-box">
        <img src="../../assets/img/academicConferences/bei.png">
      </div>

      <!-- 会议 -->
      <div
        class="levelsss"
        v-if="tabsIndex==0"
      >
        <div class="conference">
          <div class="conference-left">
            <img
              v-if="imgShow"
              src="../../assets/img/academicConferences/conference-left.png"
            >
            <img
              v-else
              src="../../assets/img/academicConferences/conference-left-y.png"
            >
          </div>
          <div class="conference-cen">
            <img src="../../assets/img/academicConferences/conference-cen.png">
          </div>
          <div class="conference-right">
            <img
              src="../../assets/img/academicConferences/conference-right.png">
          </div>
          <div
            style="padding-bottom: 160px;"
            class="conference-html"
            v-if="academic.contentMeeting"
          >
            <div
              class="html"
              v-html="academic.contentMeeting.meeting_agenda"
            ></div>
          </div>
        </div>
      </div>
      <!-- 会议 -->

      <!-- 机构 -->
      <div
        class="levelsss"
        v-if="tabsIndex==1"
      >
        <div class="conference">
          <div class="conference-left">
            <img
              v-if="imgShow"
              src="../../assets/img/academicConferences/jigou.png"
            >
            <img
              v-else
              src="../../assets/img/academicConferences/jigou-y.png"
            >
          </div>
          <div class="conference-cen">
            <img src="../../assets/img/academicConferences/conference-cen.png">
          </div>
          <div class="conference-right">
            <img
              src="../../assets/img/academicConferences/conference-right.png">
          </div>
          <div
            class="conference-html"
            v-if="academic.contentMeeting"
          >
            <div
              class="html"
              v-html="academic.contentMeeting.organization"
            ></div>
          </div>
        </div>
      </div>
      <!-- 机构 -->

      <!-- 主旨 -->
      <div
        class="levelsss"
        v-if="tabsIndex==2"
      >
        <div class="conference">
          <div class="conference-left">
            <img
              v-if="imgShow"
              src="../../assets/img/academicConferences/zhuzhi.png"
            >
            <img
              v-else
              src="../../assets/img/academicConferences/zhuzhi-y.png"
            >
          </div>
          <div class="conference-cen">
            <img src="../../assets/img/academicConferences/conference-cen.png">
          </div>
          <div class="conference-right">
            <img
              src="../../assets/img/academicConferences/conference-right.png">
          </div>
          <div
            class="conference-html"
            v-if="academic.contentMeeting"
          >
            <div
              class="html"
              v-html="academic.contentMeeting.content"
            ></div>
          </div>
        </div>
      </div>
      <!-- 主旨 -->
      <!-- 嘉宾 -->
      <div
        class="levelsss"
        v-if="tabsIndex==3"
      >
        <div class="conference">
          <div class="conference-left">
            <img
              v-if="imgShow"
              src="../../assets/img/academicConferences/jiabin.png"
            >
            <img
              v-else
              src="../../assets/img/academicConferences/jiabin-y.png"
            >
          </div>
          <div class="conference-cen">
            <img src="../../assets/img/academicConferences/conference-cen.png">
          </div>
          <div class="conference-right">
            <img
              src="../../assets/img/academicConferences/conference-right.png">
          </div>
          <div class="conference-html Introduction ">
            <!-- 1 -->
            <ul class="Introduction-ul">
              <div
                class="Introduction-ul-li-head"
                v-if="academic.contentMeeting.guest1"
              >
                <div class="Introduction-ul-li-head-top">
                  <div class="Introduction-ul-li-head-top-name">01</div>
                  <div class="Introduction-ul-li-head-top-img">
                    <img
                      src="../../assets/img/academicConferences/xiangqing-bgc.png"
                    >
                  </div>
                </div>
                <div class="Introduction-ul-li-head-bottom">
                  {{$t('i18n.ForeignKeynoteSpeechScientists')}}</div>
              </div>
              <li
                class="Introduction-ul-li"
                v-if="academic.contentMeeting"
              >
                <div
                  v-show="academic.contentMeeting.guest1"
                  class="Introduction-ul-li-box"
                  v-for="(item,index) in academic.contentMeeting.guest1"
                  :key="index"
                >
                  <div class="Introduction-ul-li-box-f">
                    <div class="Introduction-ul-li-box-f-img">
                      <img :src="imgURL+item.image">
                    </div>
                    <div class="Introduction-ul-li-box-f-name">
                      {{item.name}}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <!-- 1 -->
            <!-- 2 -->
            <ul class="Introduction-ul">
              <div
                class="Introduction-ul-li-head"
                v-if="academic.contentMeeting.guest2"
              >
                <div class="Introduction-ul-li-head-top">
                  <div class="Introduction-ul-li-head-top-name">02</div>
                  <div class="Introduction-ul-li-head-top-img">
                    <img
                      src="../../assets/img/academicConferences/xiangqing-bgc.png"
                    >
                  </div>
                </div>
                <div class="Introduction-ul-li-head-bottom">
                  {{$t('i18n.NationalKeynoteSpeechScientists')}}
                </div>
              </div>
              <li
                class="Introduction-ul-li"
                v-if="academic.contentMeeting"
              >
                <div
                  v-show="academic.contentMeeting.guest2"
                  class="Introduction-ul-li-box"
                  v-for="(item,index) in academic.contentMeeting.guest2"
                  :key="index"
                >
                  <div class="Introduction-ul-li-box-f">
                    <div class="Introduction-ul-li-box-f-img">
                      <img :src="imgURL+item.image">
                    </div>
                    <div class="Introduction-ul-li-box-f-name">
                      {{item.name}}
                    </div>
                  </div>

                </div>
              </li>
            </ul>
            <!-- 2 -->
            <!-- 3 国际 -->
            <ul class="Introduction-ul">
              <div
                class="Introduction-ul-li-head"
                v-if="academic.contentMeeting.guest3"
              >
                <div class="Introduction-ul-li-head-top">
                  <div class="Introduction-ul-li-head-top-name">03</div>
                  <div class="Introduction-ul-li-head-top-img">
                    <img
                      src="../../assets/img/academicConferences/xiangqing-bgc.png"
                    >
                  </div>
                </div>
                <div class="Introduction-ul-li-head-bottom">
                  {{$t('i18n.Internationalguestspeakers')}}
                </div>
              </div>
              <li class="Introduction-ul-li">
                <div
                  v-show="academic.contentMeeting.guest3"
                  class="Introduction-ul-li-box"
                  v-for="(item,index) in academic.contentMeeting.guest3"
                  :key="index"
                >
                  <div class="Introduction-ul-li-box-f">
                    <div class="Introduction-ul-li-box-f-img">
                      <img :src="imgURL+item.image">
                    </div>
                    <div class="Introduction-ul-li-box-f-name">
                      {{item.name}}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <!-- 3 -->
            <!-- 4 国内 -->
            <ul class="Introduction-ul">
              <div
                class="Introduction-ul-li-head"
                v-if="academic.contentMeeting.guest4"
              >
                <div class="Introduction-ul-li-head-top">
                  <div class="Introduction-ul-li-head-top-name">04</div>
                  <div class="Introduction-ul-li-head-top-img">
                    <img
                      src="../../assets/img/academicConferences/xiangqing-bgc.png"
                    >
                  </div>
                </div>
                <div class="Introduction-ul-li-head-bottom">
                  {{$t('i18n.Domesticguests')}}
                </div>
              </div>
              <li class="Introduction-ul-li">
                <div
                  v-show="academic.contentMeeting.guest4"
                  class="Introduction-ul-li-box"
                  v-for="(item,index) in academic.contentMeeting.guest4"
                  :key="index"
                >
                  <div class="Introduction-ul-li-box-f">
                    <div class="Introduction-ul-li-box-f-img">
                      <img :src="imgURL+item.image">
                    </div>
                    <div class="Introduction-ul-li-box-f-name">
                      {{item.name}}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <!-- 4 -->
          </div>
        </div>
      </div>
      <!-- 嘉宾 -->

    </div>
    <!--背景 -->
  </div>
</template>
   
<script> 
export default {
  name: 'academicConferencesDeatils',
  data () {
    return {
      tabsIndex: 0,
      list: [
        {
          name: this.$t('i18n.Conferenceprogramme'),
          img: require('../../assets/img/academicConferences/icon/richeng.png'),
          ximg: require('../../assets/img/academicConferences/icon/richeng-x.png')
        },
        {
          name: this.$t('i18n.Organization'),
          img: require('../../assets/img/academicConferences/icon/jigou.png'),
          ximg: require('../../assets/img/academicConferences/icon/jigou-x.png')
        },
        {
          name: this.$t('i18n.ConferenceKeynote'),
          img: require('../../assets/img/academicConferences/icon/zhuzhi.png'),
          ximg: require('../../assets/img/academicConferences/icon/zhuzhi-x.png')
        },
        {
          name: this.$t('i18n.GuestIntroduction'),
          img: require('../../assets/img/academicConferences/icon/jiabin.png'),
          ximg: require('../../assets/img/academicConferences/icon/jiabin-x.png')
        }
      ],

      imgURL: '',
      academic: {},
      imgShow: true,
    };
  },
  created () {
    if (localStorage.getItem('Language') == 'zh-cn') {
      this.imgShow = true
    } else {
      this.imgShow = false
    }
    this.imgURL = localStorage.getItem('imgURL')//切换中英文   
    this.getAcademiclist()
  },
  methods: {
    //切换tabs
    tabsClick (index) {
      this.tabsIndex = index
    },
    //获取列表
    async getAcademiclist () {
      const { data: data } = await this.$http.get('meeting/detail', {
        params: {
          id: this.$route.query.id,
          lang: localStorage.getItem('Language')//切换中英文  
        }
      })
      this.academic = data.data
    },
  }
}

</script>
<style lang="less" scoped>
.deatils {
  position: relative;
  overflow: hidden;
  background-color: #f6f6f6;
  .deatils-head {
    position: relative;
    width: 100%;
    height: 297px;
    .deatils-head-name {
      position: absolute;
      top: 50px;
      left: 190px;
      padding: 40px 55px;
      background-color: #0b3e7a;
      border-radius: 6px;
      font-size: 28px;
      color: #fff;
    }
  }

  //tabs
  .deatils-tabs {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
    z-index: 899999;
    .deatils-tabs-ul {
      position: relative;
      top: -35px;
      left: 0;
      width: 900px;
      height: 72px;
      border-radius: 10px;
      box-shadow: 0px 0px 6px 1px rgba(75, 75, 75, 0.16);
      display: flex;
      align-items: center;
      overflow: hidden;

      .deatils-tabs-ul-li {
        height: 100%;
        flex: 1;
        cursor: pointer;
        background: #ebedf1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #363636;
        transition: 0.4s all;
        .deatils-tabs-ul-li-img {
          width: 21px;
          height: 21px;
          margin-right: 7px;
        }
        .deatils-tabs-ul-li-name {
          font-size: 16px;
          font-weight: 600;
        }
      }
      .active {
        color: #e06c55;
        background: #fff;
      }
    }
  }

  //bgc
  .deatils-bgc {
    position: relative;
    min-height: 1115px;
    .deatils-bgc-box {
      // position: relative;
      position: absolute;
      width: 100%;
      height: 955px;
      background-color: #f6f6f6;
    }

    .levels {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 999;
    }
    .levelsss {
      position: relative;
      top: 0;
      left: 16.5%;
      z-index: 999;
      margin-bottom: 70px;
    }

    .conference {
      // min-height: 1044px;
      width: 1278px;
      position: relative;
      background-color: #fff;
      .conference-left {
        width: 141px;
        height: 141px;
        position: absolute;
        top: 74px;
        left: 54px;
      }
      .conference-cen {
        position: absolute;
        left: 150px;
        bottom: 0;
        width: 967px;
        height: 967px;
      }
      .conference-right {
        width: 212px;
        height: 155px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
      .conference-html {
        position: relative;
        padding: 50px 60px 64px 260px;
        line-height: 2;
        min-height: 864px;
      }
      //嘉宾介绍
      .Introduction {
        .Introduction-ul {
          margin-bottom: 148px;
          .Introduction-ul-li-head {
            display: flex;
            margin-bottom: 63px;
            .Introduction-ul-li-head-top {
              text-align: center;
              margin-right: 8px;
              .Introduction-ul-li-head-top-name {
                color: #363636;
                font-size: 16px;
                font-weight: 600;
              }
              .Introduction-ul-li-head-top-img {
                width: 59px;
                height: 20px;
              }
            }
            .Introduction-ul-li-head-bottom {
              color: #363636;
              font-size: 16px;
              font-weight: 600;
            }
          }
          .Introduction-ul-li {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .Introduction-ul-li-box {
              margin-right: 37px;
              .Introduction-ul-li-box-f {
                width: 122px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .Introduction-ul-li-box-f-img {
                  width: 122px;
                  height: 122px;
                  border-radius: 50%;
                  overflow: hidden;
                  background-color: #fff;
                }

                .Introduction-ul-li-box-f-name {
                  text-align: center;
                  width: 100%;
                  font-size: 14px;
                  color: #363636;
                  margin-top: 22px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  -o-text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }
    }
  }
  .html {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    // -webkit-line-clamp: 26;
    overflow: hidden;
    padding-bottom: 75px;
  }
}
</style>   