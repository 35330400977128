<template>
  <div class="bottom">
    <!-- <div class="bottom-img">
      <img src="../../assets/img/home/bottom.png">
    </div> -->

    <div class="bottom-contents">
      <!--左 -->
      <div class="bottom-contents-left">
        <div class="title">{{ contact.title }}</div>
        <div class="straight-line">
          <div class="straight-line-k"></div>
          <div class="straight-line-x"></div>
        </div>
        <div class="bottom-contents-left-img">
          <img class="dizhi" src="../../assets/img/home/dizhi_1.png" />
          <div>{{ contact.contentSingle.address }}</div>
        </div>

        <div class="bottom-contents-left-img">
          <img class="shouji" src="../../assets/img/home/shouji_1.png" />
          <div>{{ contact.contentSingle.contact_info }}</div>
        </div>

        <div class="bottom-contents-left-img">
          <img class="dianhua" src="../../assets/img/home/dianhua_1.png" />
          <div>{{ contact.contentSingle.phone }}</div>
        </div>

        <div class="bottom-contents-left-img">
          <img class="youxiang" src="../../assets/img/home/youxiang_1.png" />
          <div>{{ contact.contentSingle.email }}</div>
        </div>
      </div>
      <!--左 -->
      <!-- 中 -->
      <div class="bottom-contents-centering">
        <div class="title">
          {{ $t("i18n.LicensingandTechnicalSupport") }}
        </div>
        <div class="straight-line">
          <div class="straight-line-k"></div>
          <div class="straight-line-x"></div>
        </div>
        <div class="bottom-contents-centering-text">
          {{ system.licensing }}
        </div>
        <div class="title">
          <!-- TODO: 编辑查询改为投稿通道 -->
          {{ $t("i18n.SubmissionChannel") }}
        </div>
        <div class="straight-line">
          <div class="straight-line-k"></div>
          <div class="straight-line-x"></div>
        </div>
        <div class="bottom-contents-centering-w">
          {{ system.edit_the_query }}
        </div>
        <div class="bottom-contents-centering-w">ISNI: {{ system.isni }}</div>
      </div>
      <!-- 中 -->
      <!-- 右 -->
      <div class="bottom-contents-right">
        <div class="title">
          {{ $t("i18n.WebsiteStatement") }}
        </div>
        <div class="straight-line">
          <div class="straight-line-k"></div>
          <div class="straight-line-x"></div>
        </div>
        <div class="bottom-contents-right-text check" @click="aboutUsBtn(0)">
          {{ $t("i18n.AllDisclaimersApply") }}
        </div>
        <div class="bottom-contents-right-text check" @click="aboutUsBtn(1)">
          {{ $t("i18n.IntellectualPropertyRights") }}
        </div>
      </div>
      <!-- 右 -->
    </div>

    <div class="bottom-copyright">
      <div>
        <div>
          Copyright 2022 High-Med All Rights Reserved &nbsp;&nbsp;&nbsp;
          备案号：
          <a
            style="color: #fff; text-decoration: none"
            href="https://beian.miit.gov.cn/"
            >京ICP备14006252号-9</a
          >
        </div>
        <img
          v-if="false"
          class="bottom-copyright-1"
          src="../../assets/img/home/copyright-1.png"
        />
        <img
          v-if="false"
          class="bottom-copyright-2"
          src="../../assets/img/home/copyright-2.png"
        />
        <img
          v-if="false"
          class="bottom-copyright-3"
          src="../../assets/img/home/copyright-3.png"
        />
        <img
          v-if="false"
          class="bottom-copyright-4"
          src="../../assets/img/home/copyright-4.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottom",
  data() {
    return {
      contact: { contentSingle: {} },
      system: {},
    };
  },
  created() {
    this.getContactus();
    this.getSystem();
  },
  methods: {
    //联系我们
    async getContactus() {
      const { data: data } = await this.$http.get("contactus", {
        params: {
          lang: localStorage.getItem("Language"), //切换中英文
        },
      });
      this.contact = data.data;
    },
    //系统配置
    async getSystem() {
      const { data: data } = await this.$http.get("system", {
        params: {
          lang: localStorage.getItem("Language"), //切换中英文
        },
      });
      this.system = data.data;
    },
    aboutUsBtn(ind) {
      this.$router.push({ path: "/websiteStatement", query: { index: ind } });
    },
  },
};
</script>
<style scoped lang="less">
.bottom {
  width: 100%;
  max-height: 460px;
  background: url("../../assets/img/home/bottom.png") no-repeat center;
  background-size: cover;
  padding: 46px 0 0px;
  margin: 0 auto;
  .bottom-contents {
    // position: relative;
    max-width: 1540px;
    color: #fff;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    // width:100%;
    .title {
      font-size: 22px;
      margin-bottom: 20px;
    }
    .straight-line {
      display: flex;
      align-items: center;
      // justify-content: space-around;
      margin-bottom: 40px;
      .straight-line-k {
        width: 47px;
        height: 6px;
        background-color: #0647c7;
      }
      .straight-line-x {
        width: 205px;
        height: 1px;
        background-color: #fff;
      }
    }
    .bottom-contents-left {
      // margin-right: 240px;
      max-width: 590px;
      .bottom-contents-left-img {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 28px;
        .dizhi {
          width: 12px;
          height: 17px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .shouji {
          width: 12px;
          height: 17px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .dianhua {
          width: 14px;
          height: 17px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .youxiang {
          width: 15px;
          height: 13px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .bottom-contents-centering {
      // margin-right: 235px;
      font-size: 14px;
      .bottom-contents-centering-text {
        margin-bottom: 90px;
      }
      .bottom-contents-centering-w {
        margin-bottom: 20px;
      }
    }
    .bottom-contents-right {
      font-size: 14px;
      .bottom-contents-right-text {
        margin-bottom: 20px;
      }
      .check {
        cursor: pointer;
      }
    }
  }

  .bottom-copyright {
    color: #fff;
    font-size: 14px;
    width: 100%;
    height: 60px;
    background-color: #0647c7;
    // padding: 0 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    .bottom-copyright-1 {
      width: 91px;
      height: 17px;
      margin-right: 37px;
      margin-left: 93px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .bottom-copyright-2 {
      width: 86px;
      height: 17px;
      margin-right: 37px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .bottom-copyright-3 {
      width: 56px;
      height: 17px;
      margin-right: 68px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .bottom-copyright-4 {
      width: 274px;
      height: 17px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
