//临床指南 改为深度
<template>
  <div class="directions">
    <div class="periodicals-heda">
      <!-- <div class="periodicals-heda-img">
        <img src="../../assets/img/home/frame_work.png">
      </div> -->
      <div class="periodicals-heda-text">{{$t('i18n.Depth')}}</div>
    </div>
    <!-- 内容 -->
    <div class="periodicals-contents">
      <div class="periodicals-contents-img">
        <img src="../../assets/img/home/periodicals.png">
      </div>
      <ul class="periodicals-contents-ul">
        <li
          class="periodicals-contents-item"
          v-for="(item,index) in this.directionsObj.data"
          :key="index"
          @click="directionsClick(item)"
        >
          <div class="periodicals-contents-item-left">
            <div class="periodicals-contents-item-num">{{item.views}}</div>
            <div class="periodicals-contents-item-time">
              {{item.create_time_text}}
            </div>
          </div>
          <div class="periodicals-contents-item-right">
            {{item.description}}
          </div>
        </li>
      </ul>
    </div>
    <!-- 内容 -->

    <div
      class="primary-btn"
      @click="primaryBtn"
    >
      <div class="primary-flex">
        <div class="primary-flex-img">
          <img src="../../assets/img/home/periodicals-btn.png">
        </div>
        <div class="primary-flex-text">{{$t('i18n.Viewmore')}}</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'directions',
  data () {
    return {
      directionsObj: {
        data: []
      }
    };
  },
  created () {
    this.getdirections()
  },
  methods: {
    //获取数据
    async getdirections () {
      const { data: data } = await this.$http.get('medical', {
        params: {
          lang: localStorage.getItem('Language'),
          cate_id: '/clinicalGuidelines',
          search_title: '',
          search_cate: '',
          page: '1',
          list_rows: '8'
        }
      })
      this.directionsObj = data.data
    },
    //查看更多
    primaryBtn () {
      this.$router.push('clinicalGuidelines')
    },
    //点击每一个
    directionsClick (item) {
      this.$router.push({
        path: 'clinicalGuidelinesItme',
        query: {
          id: item.id
        }
      })
    },
  }

}

</script>
<style lang="less" scoped>
.directions {
  //头部
  // margin:0 auto;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  .periodicals-heda {
    margin: 58px 0;
    width: 214px;
    height: 24px;
    background:url('../../assets/img/home/frame_work.png') no-repeat center;
    background-size:100% 100%;
    position: relative;
    .periodicals-heda-text {
      position: relative;
      left:104px;
      top:-8px;
      line-height:26px;
      font-size: 20px;
      color: #393939;
      font-weight: 600;
    }
  }

  //内容
  .periodicals-contents {
    padding: 0 190px;
    position: relative;

    .periodicals-contents-img {
      max-width: 1094px;
      max-height: 490px;
      position: absolute;
      top: -100px;
      left: 50%;
      margin-left:-547px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .periodicals-contents-ul {
      position: relative;
      height: 500px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width:1540px;
      // padding:0 20px;
      margin:0 auto;
      .periodicals-contents-item {
        // max-width: 750px;
        flex:49%;
        // flex:1;
        // width:auto;
        max-height: 120px;
        // border: 1px 0 1px 0 solid #eeeeee;
        border: 1px solid #eeeeee;
        border-right: 0;
        border-left: 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:nth-child(odd) {
          border-top: 0;
          margin-right:2%;
        }
        &:nth-child(even) {
          border-top: 0;
        }
        &:nth-child(1) {
          border-top: 1px solid #eeeeee;
        }
        &:nth-child(2) {
          border-top: 1px solid #eeeeee;
        }

        .periodicals-contents-item-left {
          padding: 0 15px 0 35px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-items: center;
          border-right: 1px solid #eeeeee;
          min-width:190px;
          .periodicals-contents-item-num {
            font-size: 42px;
            color: #393939;
            font-weight: 600;
          }
          .periodicals-contents-item-time {
            font-size: 16px;
            color: #393939;
          }
        }
        .periodicals-contents-item-right {
          margin-left: 63px;
          max-width: 545px;
          font-size: 16px;
          color: #393939;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }

  //按钮
  .primary-btn {
    margin: 60px 0 100px 0;
    display: flex;
    justify-content: center;
    cursor: pointer;
    .primary-flex {
      width: 170px;
      padding: 10px 0;
      border-radius: 20px;
      background-color: #0647c7;
      display: flex;
      align-items: center;
      justify-content: center;

      .primary-flex-img {
        width: 14px;
        height: 17px;
        margin-right: 5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .primary-flex-text {
        font-size: 16px;
        color: #ffffff;
      }
    }
  }
}
</style>  