<template>
  <div class="news-list">
    <div class="news-list-head">
      <div class="news-list-head-img1">
        <img src="../../assets/img/news/xinwen1.png">
      </div>
      <div class="news-list-head-img2">
        <img
          v-if="imgShow"
          src="../../assets/img/news/qianyan_1.png"
        >
        <img
          v-else
          src="../../assets/img/news/qianyan-y.png"
        >
      </div>
    </div>
    <div class="news-list-txet">
      <img
        v-if="imgShow"
        src="../../assets/img/news/head_1.png"
      >
      <img
        v-else
        src="../../assets/img/news/head-y.png"
      >
    </div>
    <!-- 切换 -->
    <!-- <div class="news-list-tabs">
      <div class="news-list-tabs-box">
        <div
          v-for="(item,index) in tabs"
          @click="tabsClick(index)"
          class="news-list-tabs-1"
          :class="index==tabsIndex?'tabs-active':''"
        >
          <div class="news-list-tabs-1-img">
            <img :src="item.img">
          </div>
          <div>{{item.name}}</div>
        </div>
      </div>
    </div> -->
    <div class="news-list-tabs">
      <div class="news-list-tabs-box">
        <div
          v-for="(item,index) in tabs"
          @click="tabsClick(index)"
          class="news-list-tabs-1"
          :class="index==tabsIndex?'tabs-active':''"
        >
          <div v-if="index==2" class="news-list-tabs-1-img-hot">
            <img :src="item.img">
          </div>
          <div v-else class="news-list-tabs-1-img">
            <img :src="item.img">
          </div>
          <!-- :class="index==tabsIndex?'news-list-tabs-1-img':'news-list-tabs-2-img'" -->
          <div>{{item.name}}</div>
          <div class="cut-line" v-if="index!==0"></div>

        </div>
      </div>
    </div>
    <!-- 切换 -->

    <!-- 展示 -->
    <div class="news-list-box">
      <div v-show="tabsIndex==0">
        <ul class="news-list-box-re">
          <li
            class="news-list-box-re-item"
            v-for="(item,index) in  newsListHotspotsObj.data"
            :key="index"
            @click="Details(item)"
          >
            <div class="news-list-box-re-item-left">
              <div class="news-list-box-re-item-left-img">
                <img :src="imgURL+item.thumb">
              </div>
              <div class="news-list-box-re-item-left-text">
                <div class="news-list-box-re-item-left-text-head">
                  {{item.title}}
                </div>
                <div class="news-list-box-re-item-left-text-con">
                  {{item.description}}
                </div>
              </div>
            </div>
            <div class="news-list-box-re-item-right">
              <div class="news-list-box-re-item-right-top">{{item.views}}</div>
              <div class="news-list-box-re-item-right-bottom">
                {{item.create_time_text}}</div>
            </div>
          </li>
          <div class="page">
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="currentchange"
              @prev-click="prevClick"
              @next-click="nextClick"
              :current-page.sync="page"
              hide-on-single-page
              :page-count="newsListHotspotsObj.last_page"
            >
            </el-pagination>
          </div>
        </ul>
      </div>
      <!-- 展示 -->
      <!-- 热点 -->
      <div v-show="tabsIndex==1">
        <ul class="news-list-box-re">
          <li
            class="news-list-box-re-item"
            v-for="(item,index) in  newsListHotspotsObj.data"
            :key="index"
            @click="Details(item)"
          >
            <div class="news-list-box-re-item-left">
              <div class="news-list-box-re-item-left-img">
                <img :src="imgURL+item.thumb">
              </div>
              <div class="news-list-box-re-item-left-text">
                <div class="news-list-box-re-item-left-text-head">
                  {{item.title}}
                </div>
                <div class="news-list-box-re-item-left-text-con">
                  {{item.description}}
                </div>
              </div>
            </div>
            <div class="news-list-box-re-item-right">
              <div class="news-list-box-re-item-right-top">{{item.views}}</div>
              <div class="news-list-box-re-item-right-bottom">
                {{item.create_time_text}}</div>
            </div>
          </li>
          <div class="page">
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="currentchange"
              @prev-click="prevClick"
              @next-click="nextClick"
              :current-page.sync="page"
              hide-on-single-page
              :page-count="newsListHotspotsObj.last_page"
            >
            </el-pagination>
          </div>
        </ul>
      </div>
      <!-- 热点 -->

    </div>
    <!-- 展示 -->

  </div>
</template> 
  
<script>  
export default {
  name: 'newsListHot',
  data () {
    return {
      tabsIndex: 0,
      cate_id: '/newsListHot',
      tabs: [
        { name: this.$t('i18n.All'), img: require('../../assets/img/news/show_all.png') },
        { name: this.$t('i18n.Frontier'), img: require('../../assets/img/news/qianyan.png') },
        { name: this.$t('i18n.HotNews'), img: require('../../assets/img/news/hotspot.png') },
        { name: this.$t('i18n.Forum'), img: require('../../assets/img/news/forum.png') },
        { name: this.$t('i18n.Community'), img: require('../../assets/img/news/community.png') },
        { name: this.$t('i18n.Highlight'), img: require('../../assets/img/news/playback.png') }
      ],
      imgURL: '',
      page: 1,//页码
      list_row: 5,//条数 
      newsListHotspotsObj: {},
      imgShow: true
    }
  },
  created () {
    this.imgURL = localStorage.getItem('imgURL')
    this.getNewsList()
    if (localStorage.getItem('Language') == 'zh-cn') {
      this.imgShow = true
    } else {
      this.imgShow = false
    }
  },
  methods: {
    //切换 
    tabsClick (index) {
      this.tabsIndex = index
      switch(this.tabsIndex){
        case 0: this.cate_id = '/newsListAll';
        break;
        case 1: this.cate_id = '/newsListHotspots';
        break;
        case 2: this.cate_id = '/newsListHot';
        break;
        case 3: this.cate_id = '/newsListForum';
        break;
        case 4: this.cate_id = '/newsListCommunity';
        break;
        case 5: this.cate_id = '/newsListProgress';
        break;
        default: this.cate_id = '/newsListAll';

      }
      this.getNewsList()
    },
    //获取列表 
    async getNewsList () {
      const { data: data } = await this.$http.get('list', {
        params: {
          cate_id: this.cate_id,
          page: this.page,
          list_row: this.list_row,
          lang: localStorage.getItem('Language')//切换中英文  
        }
      })
      console.log(data.data,226)
      this.newsListHotspotsObj = data.data
    },
    //详情
    Details (item) {
      this.$router.push({
        path: 'newsListItem',
        query: {
          id: item.id
        }
      })
    },
    //点击每一页 
    currentchange (e) {
      this.page = e
      setTimeout(() => {
        this.getNewsList()
      }, 500)
      // this.$Spin.show(); 
    },
    //上一页  
    prevClick (e) {
      this.page = e
      setTimeout(() => {
        this.getNewsList()
      }, 500)
      // this.$Spin.show();
    },
    //下一页 
    nextClick (e) {
      this.page = e
      setTimeout(() => {
        this.getNewsList()
      }, 500)
      // this.$Spin.show();
    },
  }
}

</script> 
<style lang="less" scoped>
.news-list {
  .news-list-head {
    position: relative;
    .news-list-head-img1 {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 144px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .news-list-head-img2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 719px;
      height: 44px;
    }
  }

  .news-list-txet {
    height: 144px;
    width: 100%;
  }

  .news-list-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .news-list-tabs-box {
      position: relative;
      top: -27.5px;
      // width: 380px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      cursor: pointer;
      .news-list-tabs-1 {
        width: 190px;
        height: 56px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #262323;
        transition: 0.4s all;
        position:relative;
        .news-list-tabs-1-img {
          margin-right: 10px;
          width: 24px;
          height: 24px;
        }
        .news-list-tabs-1-img-hot {
          margin-right: 10px;
          width: 18px;
          height: 27px;
        }
        .cut-line{
          position:absolute;
          left:-1px;
          margin:auto 0;
          width: 0px;
          height: 40px;
          opacity: 1;
          border: 1px solid #E5E5E5;
        }
      }
      .news-list-tabs-2-img {
        margin-right: 10px;
        width: 22px;
        height: 19px;
      }

      .tabs-active {
        background: #0647c7;
        color: #fff;
        z-index:1;
        .cut-line{
          // position:absolute;
          // left:-1px;
          // margin:auto 0;
          opacity: 0;
        }
      }

      .news-list-tabs-2 {
        width: 190px;
        height: 55px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #262323;
        .news-list-tabs-2-img {
          width: 22px;
          height: 19px;
          margin-right: 10px;
        }
      }
    }
  }

  .news-list-box {
    padding: 0 190px;
    margin-top: 35px;
    .news-list-box-re {
      width: 100%;
      .news-list-box-re-item {
        display: flex;
        justify-content: space-between;
        border-radius: 11px;
        box-shadow: 0px 3px 10px 1px #ebebeb;
        margin-bottom: 30px;
        cursor: pointer;

        .news-list-box-re-item-left {
          padding: 15px 0;
          padding-left: 20px;
          display: flex;
          align-items: center;
          .news-list-box-re-item-left-img {
            width: 245px;
            height: 138px;
            margin-right: 30px;
          }
          .news-list-box-re-item-left-text {
            width: 990px;
            .news-list-box-re-item-left-text-head {
              font-size: 18px;
              color: #393939;
              font-weight: 600;
              margin-bottom: 35px;
            }
            .news-list-box-re-item-left-text-con {
              font-size: 15px;
              color: #999999;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
            }
          }
        }

        .news-list-box-re-item-right {
          .news-list-box-re-item-right-top {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50%;
            width: 115px;
            background-color: #e9e9e9;
            border-radius: 0 11px 0 0;
          }
          .news-list-box-re-item-right-bottom {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50%;
            width: 115px;
            background-color: #fff;
            border: 3px solid #e9e9e9;
            border-radius: 0 0 11px 0;
          }
        }
      }
      .page {
        margin: 20px 0 55px 0;
        text-align: center;
        // /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        //   background-color: rgb(255, 116, 116);
        // }
        // /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
        //   color: rgb(255, 116, 116);
        // }
      }
    }
  }
}
</style>     