import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home/Home.vue'
import homeContents from '../views/HomeContents/homeContents.vue'

import newsListHotspots from '../views/news/newsListHotspots.vue'
import newsListProgress from '../views/news/newsListProgress.vue'
import newsListHot from '../views/news/newsListHot.vue'
import newsListForum from '../views/news/newsListForum.vue'
import newsListCommunity from '../views/news/newsListCommunity.vue'
import newsListAll from '../views/news/newsListAll.vue'
import news from '../views/news/news.vue'

import newsListItem from '../views/news/newsListItem.vue'

import clinicalGuidelines from '../views/clinicalGuidelines/index.vue'
import clinicalGuidelinesItme from '../views/clinicalGuidelines/clinicalGuidelinesItme.vue'

import academicConferences from '../views/academicConferences/index.vue'
import academicConferencesDeatils from '../views/academicConferences/academicConferencesDeatils.vue'

import china from '../views/theBrainProject/china.vue'
import europeAmerica from '../views/theBrainProject/europeAmerica.vue'
import unitedStates from '../views/theBrainProject/unitedStates.vue'

import periodicals from '../views/periodicals/index.vue'
import aboutMembers from '../views/periodicals/aboutMembers.vue'
import expertDetails from '../views/periodicals/expertDetails.vue'

import humanBrainBank from '../views/humanBrainBank/index.vue'

import websiteStatement from "../views/websiteStatement/index.vue"
//! 解决路由重复点击   
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [{
        path: '/',
        redirect: '/homeContents'
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        children: [{
                path: '/',
                redirect: '/homeContents'
            },
            {
                path: '/homeContents',
                name: 'homeContents',
                component: homeContents,
            },
            {
              path: '/news',
              name: 'news',
              component: news,
              // children:[
              //   {
              //     path: '/',
              //     name: 'newsListAll',
              //     component: newsListAll
              //   },
              //   {
              //     path: '/newsListAll',
              //     name: 'newsListAll',
              //     component: newsListAll
              //   },
              //   {
              //       path: '/newsListProgress',
              //       name: 'newsListProgress',
              //       component: newsListProgress,
              //   },
              //   {
              //     path: '/newsListHot',
              //     name: 'newsListHot',
              //     component: newsListHot,
              //   },
              //   {
              //     path: '/newsListCommunity',
              //     name: 'newsListCommunity',
              //     component: newsListCommunity,
              //   },
              //   {
              //     path: '/newsListForum',
              //     name: 'newsListForum',
              //     component: newsListForum,
              //   },
              // ]
            },
            {
              path: '/newsListHotspots',
              name: 'newsListHotspots',
              component: newsListHotspots,
            },
            {
                path: '/newsListItem',
                name: 'newsListItem',
                component: newsListItem,
            },
            {
                path: '/clinicalGuidelines',
                name: 'clinicalGuidelines',
                component: clinicalGuidelines,
            },
            {
                path: '/clinicalGuidelinesItme',
                name: 'clinicalGuidelinesItme',
                component: clinicalGuidelinesItme,
            },
            {
                path: '/academicConferences',
                name: 'academicConferences',
                component: academicConferences,
            },
            {
                path: '/academicConferencesDeatils',
                name: 'academicConferencesDeatils',
                component: academicConferencesDeatils,
            },
            {
                path: '/china',
                name: 'china',
                component: china,
            },
            {
                path: '/europeAmerica',
                name: 'europeAmerica',
                component: europeAmerica,
            },
            {
                path: '/unitedStates',
                name: 'unitedStates',
                component: unitedStates,
            },
            {
                path: '/periodicals',
                name: 'periodicals',
                component: periodicals,
            },
            {
                path: '/aboutMembers',
                name: 'aboutMembers',
                component: aboutMembers,
            },
            {
                path: '/expertDetails',
                name: 'expertDetails',
                component: expertDetails,
            },
            {
                path: '/humanBrainBank',
                name: 'humanBrainBank',
                component: humanBrainBank,
            },
            {
                path: '/websiteStatement',
                name: 'websiteStatement',
                component: websiteStatement
            }
        ]
    },
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        // return 期望滚动到哪个的位置
        return { x: 0, y: 0 } // x控制左右，y控制上下
    }
})
Vue.use(VueRouter)
export default router